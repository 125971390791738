import { format } from 'date-fns';

const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
const US_DATE_FORMAT = 'MM/dd/yyyy';

const formatDate = (date, isUSCountryCode) => {
  const dateFormat = isUSCountryCode ?  US_DATE_FORMAT : DEFAULT_DATE_FORMAT;
  const formattedDate = format(date, dateFormat);

  return formattedDate;
}

export default formatDate;
