import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderItemList from './OrderItemList';
import "./ReturnExchangeOrderItemList.scss";

export default function ReturnExchangeOrderItemList(props) {
    const { t } = useTranslation();
    const ReturnOrderItem = props.returnItemType;
    const ExchangeOrderItem = props.exchangeItemType;
    const returnedItems = props.items.filter(item => item.isReturn === true);
    const exchangedItems = props.items.filter(item => item.isExchange === true);

    if (returnedItems.length === 0 && exchangedItems.length === 0) {
        return null;
    }

    return (
        <div className="ReturnExchangeOrderItemList">
            {props.heading && (returnedItems.length > 0 || exchangedItems.length > 0) &&
                <h3 className="headlineMedium">{props.heading}</h3>
            }

            <OrderItemList
                items={returnedItems}
                title={t('Returns')}
                introduction={props.returnsIntroduction}
                itemType={ReturnOrderItem}
                isMiniCartExpanded={props.isMiniCartExpanded}
                setItemAdded={props.setItemAdded}
                isDisableInputs={props.isDisableInputs}
            />
            <OrderItemList
                items={exchangedItems}
                title={t('Exchanges')}
                introduction={props.exchangesIntroduction}
                itemType={ExchangeOrderItem}
                isMiniCartExpanded={props.isMiniCartExpanded}
                setItemAdded={props.setItemAdded}
                isDisableInputs={props.isDisableInputs}
            />
        </div>
    );
};