import React from 'react';
import { useUser } from '../contexts/User';
import { Trans, useTranslation } from 'react-i18next';
import formatDate from '../helpers/formatDate';

export default function OrderInfo() {
    const user = useUser();
    const { t } = useTranslation();

    const getLatestReturnAt = () => {
        const {order: { countryCode, latestReturnAt }} = user;

        let date = new Date(latestReturnAt);

        // Check for invalid dates
        if (! date instanceof Date || isNaN(date)) {
            date = new Date();
        }

        const isUSCountryCode = countryCode === 'US'

        return formatDate(date, isUSCountryCode);
    };

    return(
        <div>
            <h2>{t('Your Order')} <span dir="ltr">#{user.order.id}</span></h2>
            <p className="titleSmall">
                <Trans i18nKey="orderInfoText">
                    These items from order <strong>#{{orderId: user.order.id}}</strong> can be returned until <strong>{{latestReturnAt: getLatestReturnAt()}}</strong>
                </Trans>
            </p>
        </div>
    );
};