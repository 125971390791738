import React from 'react'
import { useUser } from '../contexts/User';
import { useTranslation } from 'react-i18next';

export default function CustomerInfo(props) {
    const { i18n, t } = useTranslation();
    const user = useUser();

    return(
        <div className="CustomerInfo">
            <h1>
                <span className="customerName">{t('welcomeText', {firstname: user.order.customerFirstname, lastname: user.order.customerLastname})}</span>
            </h1>
            <p className="titleSmall pt-2">{user.order.emailAddress}</p>
        </div>
    );
};