import React from 'react';
import './CloseIcon.scss';

export default function CloseIcon(props) {
    return (
        <div className="CloseIcon">
            <div className="crossX"></div>
            <div className="crossY"></div>
        </div>
    )
}