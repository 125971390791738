import React, { useEffect } from 'react';
import './DigitalReturnsApp.scss';
import {Switch, Route, useLocation, Redirect} from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute'
import Login from './components/pages/Login';
import Header from './components/Header';
import {useUser} from './contexts/User';
import OrderDetails from './components/pages/OrderDetails'
import ReturnDetails from './components/pages/ReturnDetails';
import Success from './components/pages/Success';
import Error from './components/pages/Error';
import ScrollToTop from './components/ScrollToTop';
import UspBar from './components/UspBar';
import Footer from './components/Footer';
import LoadingBar from './components/LoadingBar';
import { useTranslation } from 'react-i18next';
import { useTracking } from './contexts/Track';
import SessionRefresher from "./components/SessionRefresher";

export default function DigitalReturnsApp() {
    const location = useLocation();
    const { i18n } = useTranslation();
    const user = useUser();
    const { Track } = useTracking();

    useEffect(() => {
        // remove all route class names beginning with /
        document.body.classList.forEach(className => {
            if(className.startsWith('/')) {
                document.body.classList.remove(className) ;
            }
        });

        // use last part of path to set route specific css class
        document.body.classList.add('/' + location.pathname.split('/').filter(item => item).pop());
    }, [location]);

    return (
        <div className="DigitalReturnsApp min-vh-100 d-flex flex-column">
            <SessionRefresher isRefreshSession={location.pathname.substr(-('/success'.length)) === '/success' ? false : true} />
            <ScrollToTop />
            <Track>
                <LoadingBar now={user.loading} />
                <UspBar />
                <Header/>
                <div className="content container-fluid flex-fill">
                    <div className="content py-5">
                        <Switch>
                            <Route path="/:langUrlParam/" exact={true} component={Login}/>
                            <PrivateRoute path="/:langUrlParam/order-details" exact={true} component={OrderDetails}/>
                            <PrivateRoute path="/:langUrlParam/return-details" exact={true} component={ReturnDetails}/>
                            <PrivateRoute path="/:langUrlParam/success" exact={true} component={Success}/>
                            <PrivateRoute path="/:langUrlParam/error" exact={true} component={Error}/>
                            <Route path="*" render={() => (<Redirect to={"/" + i18n.language.substr(0, 2)}/>)} />
                        </Switch>
                    </div>
                </div>
                <Footer />
            </Track>
        </div>
    );
}