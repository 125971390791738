import React, { useEffect, useState, useRef } from 'react';
import { refreshSession } from './../api/Session';
import { useErrorDispatch } from './../contexts/Error';
import {useUser, useUserDispatch} from './../contexts/User';
import { useLocation } from 'react-router-dom';

export default function SessionRefresher(props) {
    const user = useUser();
    const dispatchErrorUpdate = useErrorDispatch();
    const dispatchUserUpdate = useUserDispatch();
    const [isUserActive, setIsUserActive] = useState(true);
    const isUserActiveRef = useRef(isUserActive);
    isUserActiveRef.current = isUserActive;
    const userRef = useRef(user);
    userRef.current = user;
    const location = useLocation();

    const refreshSessionListener = (event) => {
        setIsUserActive(true);
    };

    const refreshSessionIfLoggedIn = () => {
        if (userRef.current.isLoggedIn !== true) {
            return;
        }

        if (userRef.current.sessionExpiresAt > 0 && userRef.current.sessionExpiresAt <= Date.now() / 1000) {
            // trigger session timeout
            dispatchErrorUpdate({type: 'API_ERROR', payload: { response: { data: { type: "https://returns.mytheresa.services/problems/DRP10007"}}}});
            dispatchUserUpdate({ type: 'LOGOUT' });
            return;
        }

        if (isUserActiveRef.current !== true) {
            return;
        }

        refreshSession(userRef.current.order, dispatchErrorUpdate, dispatchUserUpdate);
        setIsUserActive(false);
    };

    useEffect(() => {
        if (props.isRefreshSession === false) {
            return;
        }

        window.addEventListener('keydown', refreshSessionListener);
        window.addEventListener('mousedown', refreshSessionListener);

        refreshSessionIfLoggedIn();

        const timer = setInterval(refreshSessionIfLoggedIn, 60000);

        return function cleanup() {
            clearInterval(timer);
            window.removeEventListener('keydown', refreshSessionListener);
            window.removeEventListener('mousedown', refreshSessionListener);
        };
    }, [location]);

    return null;
}