import React from 'react';
import { useFormikContext } from 'formik';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

export default function AutoLogin({recaptchaRef}) {
    const location = useLocation();
    const { setSubmitting, setTouched, setValues, validateForm } = useFormikContext()

    const validateQueryParamValues = async (orderId, email) => {
      try {
        const errors = await validateForm({
          orderId,
          email
        })

        const isValidationSuccess = Object.keys(errors).length === 0
  
        if (isValidationSuccess) {
          recaptchaRef.current.execute()
          setSubmitting(true)
        }
      } catch(error){
        // do nothing
      }
    }

    React.useEffect(() => {
        let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

        // o = orderId, e = email, both base64 encoded
        if (
          typeof queryParams.o != 'undefined' &&
          typeof queryParams.e != 'undefined'
        ) {
          try {
            const orderId = atob(queryParams.o)
            const email = atob(queryParams.e)

            setValues({
              orderId,
              email,
            })

            // important for validation
            setTouched({
              orderId: true,
              email: true,
            })

            validateQueryParamValues(orderId, email)
          } catch (error) {
            // do nothing
          }
        }
    }, [location.search]);

    return null;
};