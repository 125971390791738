import React, { useEffect, useState } from 'react';
import './LoadingBar.scss';
import ProgressBar from 'react-bootstrap/ProgressBar'

export default function LoadingBar(props) {
    const [loadingBarPercentage, setLoadingBarPercentage] = useState(props);

    useEffect(() => {
        setLoadingBarPercentage(props.now);

        if (props.now === 100) {
            // should be the same timeout like the transition duration defined in the css
            setTimeout(() => setLoadingBarPercentage(0), 1000)
        }
    }, [props.now]);

    if (props.now === false) {
        return null;
    }


    return(
        <ProgressBar className="LoadingBar" {...props} now={loadingBarPercentage} />
    );
};