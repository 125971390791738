import React from 'react';
import { useField } from 'formik';
import { useUser } from '../contexts/User';
import { Trans, useTranslation } from 'react-i18next';
import MobileDesktopDropdown from './MobileDesktopDropdown';
import './NumberOfPackagesSelect.scss';
import boxImage from '../assets/box.jpg';

export default function NumberOfPackagesSelect(props) {
    const user = useUser();
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(props);

    const getPackageAmountOptions = function() {
        let packageAmountOptions = [];
        let maxNumberOfPackages = Math.min(
            user.order.maxNumberOfPackages,
            user.order.items.filter(item => (item.isReturn === true || item.isExchange === true)).length
        );

        for (let counter = 1; counter <= maxNumberOfPackages; counter++) {
            packageAmountOptions.push({
                key: counter.toString(),
                value: counter.toString()
            })
        }

        return packageAmountOptions;
    }

    return(
        <div className="NumberOfPackagesSelect py-4">
            <h3 class="mt-3 pb-3">{t('Number of packages')}</h3>
            <div className="row pt-3">
                <div className="col-4 col-sm-3 col-md-2">
                    <img className="img-fluid productImage" src={boxImage} />
                </div>
                <div className="col-4 pt-lg-5 pt-sm-3">
                    {t('How many packages are you returning?')}
                </div>
                <div className="col-sm-12 justify-content-end mx-auto mx-md-0 mt-3 col-md-7 col-lg-5 col-xl-6 mt-3 mt-lg-0">
                    <MobileDesktopDropdown name="packageAmount" label={t('Please select the amount')} defaultOptionLabel="Choose quantity*" {...props} {...field} options={getPackageAmountOptions()} />
                </div>
            </div>
        </div>
    );
};