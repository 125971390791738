import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.svg';
import './Header.scss';
import Menu from "./Menu";
import Navbar from 'react-bootstrap/Navbar';
import GenderMenu from "./GenderMenu";
import LanguageMenu from "./LanguageMenu";
import MenuBurger from "./MenuBurger";

export default function Header(props) {
    const { t } = useTranslation();
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
    const handleNavCollapse = (expanded) => {
        setIsNavbarCollapsed(!expanded);
        document.body.classList.toggle('menuActive');
    };

    return (
        <header className="Header">
            <Navbar className="topMenu px-lg-5 d-none d-lg-block">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <GenderMenu />
                        </div>
                        <div className="col d-flex justify-content-end">
                            <LanguageMenu />
                        </div>
                    </div>
                </div>
            </Navbar>
            <Navbar className="mainMenu px-lg-5 py-lg-3" collapseOnSelect expand="lg" onToggle={handleNavCollapse}>
                <div className="container-fluid">
                    <Navbar.Brand className="mx-auto pt-0" href="https://www.mytheresa.com/to?services=returns&type=logo" target="_blank"><img className="logo" alt={t('Mytheresa')} src={logo} /></Navbar.Brand>

                    <MenuBurger isNavbarCollapsed={isNavbarCollapsed} />
                    <Menu id="navbarMainMenu" />
                </div>
            </Navbar>
        </header>
    )
}