import React, {useEffect} from 'react';
import './Success.scss';
import { useTranslation, Trans } from 'react-i18next';
import { useUser, useUserDispatch } from '../../contexts/User';
import { Link } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import {userActionTypes} from '../../contexts/PersistUserReducer';
import OrderItemList from '../OrderItemList';
import NonFunctionalDisplayOrderItem from '../NonFunctionalDisplayOrderItem';
import PdfBase64DownloadButton from '../PdfBase64DownloadButton';
import ErrorMessage from '../ErrorMessage';
import SupportContact from '../SupportContact';

export default function Success(props) {
    const { t } = useTranslation();
    const user = useUser();
    const dispatchUserUpdate = useUserDispatch();
    const proFormaInvoiceFileName = 'Pro-Forma-Invoice_' + user.order.id + '.pdf';
    const returnLabelFileName = 'Returns-Label_' + user.order.id + '.pdf';
    const qrCodeFileName = 'QR-Code_' + user.order.id + '.pdf';
    const numberOfPackages = props.location.submitReturnResponse.length;
    const firstFulfilledReturnResponse = props.location.submitReturnResponse.filter(item => item.status === 'fulfilled')[0];
    const isSubmitReturnError = props.location.submitReturnResponse.filter(item => item.status === 'rejected').length > 0
    const isFedexShippingAgent = firstFulfilledReturnResponse.value.data.submitResponse.shippingAgentCode === 'FEDEX'
    const isDHLShippingAgent = firstFulfilledReturnResponse.value.data.submitResponse.shippingAgentCode === 'DHL'
    const isHongKong = user.order.countryCode === 'HK'
    const isCanada = user.order.countryCode === 'CA'
    const isFedexQRCodeAvailable = isFedexShippingAgent && !isCanada
    const isFedexCanada = isFedexShippingAgent && isCanada
    const isHongKongDHL = isHongKong && isDHLShippingAgent
    const isQRCodeAvailableForOneParcel = isFedexQRCodeAvailable || isDHLShippingAgent
    const isHongKongDHLQRCode = isHongKongDHL && firstFulfilledReturnResponse.value.data.submitResponse.base64PdfQrCode

    useEffect(() => {
        dispatchUserUpdate({ type: userActionTypes.clearSession });
    });

    useTracking({
        object: 'form', action: 'step', form: {pathId: '5.0'}, returns: {
            products: user.order.items.map(function (item) {
                return {
                    id: item.itemNumber,
                    name: item.itemDescription,
                    brandName: item.designerName,
                    price: item.formattedItemPrice
                }
            })
        }
    }, {dispatchOnMount: true});
    useTracking({ object: 'page', action: 'view', page: { name: 'Success' } }, { dispatchOnMount: true });

    const printShipmentProviderText = function(dhlText, fedexText, fedexTaiwanText, dhlHKText, forceDHLHKText = false) {
        if (isFedexShippingAgent) {
            if (user.order.countryCode === 'TW') {
                return fedexTaiwanText;
            }

            return fedexText;
        }
        
        if (isHongKongDHL && forceDHLHKText) return dhlHKText;
        if (isHongKongDHLQRCode) return dhlHKText;

        return dhlText;
    };

    const getClassStatus = (primaryClass, status) => {
        if (status === 'rejected') return `${primaryClass} inactive`;

        return primaryClass;
    }

    const renderDropOffText = () => {
        if (isHongKongDHLQRCode) return <p>{t('success.HKDHLCourier.paragraph4')}</p>
        if (isFedexShippingAgent) return <p>{t('For drop-offs, you can leave your package at a FedEx station. Please use the locator for the one nearest to you.')}</p>

        return  <p>{t('For drop-offs, you can leave your labeled DHL package at a drop-off store. Please use the locator for the one nearest to you.')}</p>
    }

    return (
        <div className="Success">
            <div>
                <div className="row text-center justify-content-center">
                    <div className="col-md-8">
                        {isSubmitReturnError &&
                            <ErrorMessage>{t('There was an issue with your return')}</ErrorMessage>
                        }
                        <h1>{t('You are almost there!')}</h1>
                        <p className="titleSmall mt-2">
                            <Trans i18nKey="confirmationEmailText">
                                A confirmation email has been sent to {{email: user.order.emailAddress}}
                            </Trans>
                        </p>

                        <h2 className="mt-5">
                            {numberOfPackages === 1 ? t('How to get your items back to us') : t('Your order will be returned to different locations. Please pack the items as shown below and use the respective label.')}
                        </h2>

                        {numberOfPackages === 1 &&
                            <div>
                                <h3 className="headlineMedium text-uppercase mt-5">
                                    {printShipmentProviderText(t('Print returns documents'), t('Obtain return documents'), t('Obtain return documents'), t('success.HKDHLCourier.title'))}
                                </h3>
                                { isHongKongDHLQRCode && <p>{t('success.HKDHLCourier.paragraph1')}</p> }
                                <p>
                                    {printShipmentProviderText(
                                        t('Should you prefer to print them later, we have attached the relevant documents in your return confirmation email.'),
                                        isFedexQRCodeAvailable ? t('You can either print your returns label or show the QR code at a FedEx Station.') : '',
                                        '',
                                        t('success.HKDHLCourier.paragraph2')
                                    )}
                                </p>
                                <p>
                                    {printShipmentProviderText(
                                        '',
                                        isFedexCanada ? t('If you prefer to print later, we attached all relevant documents to your return confirmation email.') : t('Should you need to access them later, we have attached the relevant documents in your return confirmation email.'),
                                        t('If you prefer to print later, we attached all relevant documents to your return confirmation email.'),
                                        ''
                                    )}
                                </p>
                            </div>
                        }
                    </div>
                </div>
                {numberOfPackages === 1 &&
                    <div className="row text-center justify-content-center mt-2">
                        <div className="col-lg-6">
                            <div className="btn-group d-inline-flex flex-column flex-lg-row" role="group" aria-label="Download Label">
                                <PdfBase64DownloadButton title="Print return label" fileName={returnLabelFileName} pdfBase64={firstFulfilledReturnResponse.value.data.submitResponse.base64PdfLabel} trackingData={{ object: 'form', action: 'step', form: { pathId: '6.0' } }}/>
                                {firstFulfilledReturnResponse.value.data.submitResponse.base64ProformaInvoice && <PdfBase64DownloadButton title="Print Pro-Forma invoice" fileName={proFormaInvoiceFileName} pdfBase64={firstFulfilledReturnResponse.value.data.submitResponse.base64ProformaInvoice} />}
                                {firstFulfilledReturnResponse.value.data.submitResponse.base64PdfQrCode && isQRCodeAvailableForOneParcel && <PdfBase64DownloadButton title="Download QR code" fileName={qrCodeFileName} pdfBase64={firstFulfilledReturnResponse.value.data.submitResponse.base64PdfQrCode} />}
                            </div>
                        </div>
                    </div>
                }
                <div className="row justify-content-center mt-5 parcelsRow">
                    {numberOfPackages > 1 && props?.location?.submitReturnResponse?.map((submitReturnResponse, counter) => {
                        const shipmentLocation = props?.location?.submitReturnOrders[counter]?.items[0]?.shippingLocation
                        const returnLabelFileNameByLocation = shipmentLocation ? `${shipmentLocation}_${returnLabelFileName}` : returnLabelFileName

                        return (
                            <div className={'col-lg-4' + (counter > 0 ? ' mt-5 mt-lg-0' : '')}>
                                {submitReturnResponse?.status === 'rejected' &&
                                    <div className="alert alert-info text-center mb-4">
                                        <a href="#supportContact">{t('We are encountering an issue with part of your return. Kindly contact our Customer Care team. We apologize for the inconvenience.')}</a>
                                    </div>
                                }

                                <div className={getClassStatus('' ,submitReturnResponse?.status)}>
                                    <h2 className="headlineMedium text-uppercase">
                                        {t('Parcel')} {counter + 1}
                                    </h2>
                                    <p>
                                        {t('This parcel will go to ' + shipmentLocation + ', Germany')}
                                    </p>
                                    {numberOfPackages === 1 &&
                                        <div>
                                            <p>
                                                {printShipmentProviderText(t('Should you prefer to print them later, we have attached the relevant documents in your return confirmation email.'), t('You can either print your returns label or show the QR code at a FedEx Station.'), '', '')}
                                            </p>
                                            <p>
                                                {printShipmentProviderText('', t('Should you need to access them later, we have attached the relevant documents in your return confirmation email.'), t('If you prefer to print later, we attached all relevant documents to your return confirmation email.'), '')}
                                            </p>
                                        </div>
                                    }
                                </div>
                                {submitReturnResponse?.status === 'fulfilled' &&
                                    <div className="justify-content-center">
                                        <div className="btn-group-vertical" aria-label="Download Label">
                                            <PdfBase64DownloadButton title="Print return label" fileName={returnLabelFileNameByLocation} pdfBase64={submitReturnResponse?.value?.data?.submitResponse?.base64PdfLabel} trackingData={{ object: 'form', action: 'step', form: { pathId: '6.0' } }}/>
                                            {submitReturnResponse?.value?.data?.submitResponse?.base64ProformaInvoice && <div className="mt-2"><PdfBase64DownloadButton title="Print Pro-Forma invoice" fileName={proFormaInvoiceFileName} pdfBase64={submitReturnResponse?.value?.data?.submitResponse?.base64ProformaInvoice} /></div>}
                                            {submitReturnResponse?.value?.data?.submitResponse?.base64PdfQrCode && isFedexQRCodeAvailable && <div className="mt-2"><PdfBase64DownloadButton title="Download QR code" fileName={qrCodeFileName} pdfBase64={submitReturnResponse?.value?.data?.submitResponse?.base64PdfQrCode} /></div>}
                                        </div>
                                    </div>
                                }
                                <OrderItemList className={getClassStatus('pt-3', submitReturnResponse?.status)} items={props?.location?.submitReturnOrders[counter]?.items} itemType={NonFunctionalDisplayOrderItem}></OrderItemList>
                            </div>
                        )
                    })}
                </div>
                {isSubmitReturnError &&
                    <div id="supportContact" className="mt-5">
                        <SupportContact message="Please contact our customer care team:" channelCode={user.order.channelCode} />
                    </div>
                }
                <div className="row text-center justify-content-center mt-5">
                    <div className="col-md-6 col-xl-4">
                        <h3 className="headlineMedium text-uppercase">
                            {t('handOverParcel', {count: user.order.packageAmount})}
                        </h3>

                        <p>
                            {isFedexShippingAgent ? t('Please choose your preferred option for handing over your parcel to FedEx.') : t('Please choose your preferred option for handing over your parcel to DHL.')}
                        </p>
                    </div>
                </div>

                <div className="row text-center justify-content-center mt-2">
                    <div className="col-lg-12">
                        <div className="btn-group d-inline-flex flex-column flex-lg-row" role="group"
                             aria-label="Choose how to hand over your parcel">
                            <a className="btn btn-secondary" target="_blank"
                               href={printShipmentProviderText(firstFulfilledReturnResponse.value.data.submitResponse.dhlPickupUrl, 'tel:+1-800-463-3339', 'https://www.fedex.com', firstFulfilledReturnResponse.value.data.submitResponse.dhlPickupUrl)}
                               rel="noopener noreferrer">
                                {printShipmentProviderText(t('Book a DHL pick-up'), t('button.bookFedexPickup'), t('Schedule a pick-up'), t('Book a DHL pick-up'))}
                            </a>
                            <a className="btn btn-secondary" target="_blank"
                               href={printShipmentProviderText('https://locator.dhl.com', 'https://local.fedex.com/en/easy-returns', 'https://local.fedex.com/en-tw', 'https://locator.dhl.com')}
                               rel="noopener noreferrer">
                                {printShipmentProviderText(t('Find a DHL station'), t('Find a FedEx station'), t('Find a FedEx station'), t('success.HKDHLCourier.findDHLButton'), true)}
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row text-center justify-content-center copyMedium mt-4">
                    <div className="col-md-6 col-xl-4">
                        <p>
                            {printShipmentProviderText(t('pickUpsText', {waybillNumber: firstFulfilledReturnResponse.value.data.submitResponse.waybillNumber}), t('To schedule a pick up, please call (800) 463 3339, or visit fedex.com.'), t('To schedule a pick up, please call 0800-075-075, or visit fedex.com.'), t('success.HKDHLCourier.paragraph3', {waybillNumber: firstFulfilledReturnResponse.value.data.submitResponse.waybillNumber}))}
                        </p>
                        {renderDropOffText()}
                        { isHongKongDHLQRCode && <p>{t('success.HKDHLCourier.paragraph5')}</p> }
                        <p className="mt-5 pt-5">
                            <Trans i18nKey="moreReturnLabelText">
                                Do you have more returns to do? If you have more returns or exchanges to do, <Link
                                to={"/"} target="_blank">click here</Link> to start the process over again and select
                                the order you want to return or exchange anything.
                            </Trans>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};