import React, { useRef } from 'react'
import './Login.scss';
import { useTranslation, Trans } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { Formik} from 'formik';
import { loginUser } from '../../api/Order';
import { useErrorDispatch } from '../../contexts/Error';
import { useUser, useUserDispatch } from '../../contexts/User';
import FloatingInput from '../FloatingInput';
import ApiError from '../ApiError';
import ReCAPTCHA from 'react-google-recaptcha';
import AutoLogin from '../AutoLogin';
import { useTracking } from 'react-tracking';
import {
    emailRegEx,
    orderNumberRegEx,
    splitShipmentOrderNumberRegEx,
    trimOrderNumber
} from '../../helpers/login'

export default function Login(props) {
    const { t, i18n } = useTranslation();
    const dispatchErrorUpdate = useErrorDispatch();
    const dispatchUserUpdate = useUserDispatch();
    const user = useUser();
    const recaptchaRef = useRef(null);
    let { langUrlParam } = useParams();

    useTracking({ object: 'form', action: 'step', form: { pathId: '1.0' } }, { dispatchOnMount: true });
    useTracking({ object: 'page', action: 'view', page: { name: 'Login' } }, { dispatchOnMount: true });

    if (user.isLoggedIn === true) {
        return <Redirect to={'/' + langUrlParam + '/order-details'}/>
    }

    window.recaptchaOptions = {
        useRecaptchaNet: true,
    };

    const resetRecaptcha = (setSubmitting) => {
        recaptchaRef.current.reset();
        setSubmitting(false);
    };

    const handleSubmit = (values, { setSubmitting, setErrors }) => {
        const orderNumber = Object.keys(values).length !== 0 && values.orderId;
        const isSplitShipmentOrderNumber = splitShipmentOrderNumberRegEx.test(orderNumber);

        if (isSplitShipmentOrderNumber) values.orderId = trimOrderNumber(orderNumber);

        setSubmitting(true);
        recaptchaRef.current.execute();
    };

    const handleValidation = (values) => {
        const errors = {};
        const isValidOrderNumber = orderNumberRegEx.test(values.orderId)
        const isValidEmail = emailRegEx.test(values.email)

        if (values.orderId === '') {
            errors.orderId = t('Required');
        } else if (!isValidOrderNumber) {
            errors.orderId = t('Invalid order number');
        }

        if (values.email === '') {
            errors.email = t('Required');
        } else if (!isValidEmail) {
            errors.email = t('Invalid email address');
        }

        return errors;
    }

    return (
        <div className="Login row justify-content-center">
            <div className="col-md-6 col-lg-4 login-card mt-2 hv-center pb-5">
                <h1 className="pb-1">{t('Returns & Exchanges')}</h1>
                <h2 className="headlineMedium pt-3 pb-1 text-uppercase">{t('Log In')}</h2>
                <p>{t('Enter your order information to get started.')}</p>
                <ApiError />
                <Formik
                    initialValues={{ orderId: '', email: '' }}
                    validate={ handleValidation }
                    onSubmit={ handleSubmit }
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setSubmitting,
                        dirty,
                        isValid
                        /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <FloatingInput name="orderId" label={t('Order Number') + "*"} autoComplete="off" />
                            <FloatingInput name="email" label={t('Email') + "*"} autoComplete="off" />

                            <small>{t('Email must coincide with the one from the purchase.')}</small><br />
                            <small>*{t('Required fields')}</small>

                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                                size="invisible"
                                onChange={(response) => { loginUser(values.orderId, values.email, response, dispatchErrorUpdate, dispatchUserUpdate); resetRecaptcha(setSubmitting); }}
                                onErrored={() => { resetRecaptcha(setSubmitting); }}
                                onExpired={() => { resetRecaptcha(setSubmitting); }}
                                badge={i18n.dir() === 'ltr' ? 'bottomright' : 'bottomleft'}
                            />
                            <div className="text-center"><button type="submit" className="btn btn-primary" disabled={!(dirty && isValid)}><Trans>Next</Trans></button></div>
                            <AutoLogin recaptchaRef={recaptchaRef} />
                        </form>
                    )}
                </Formik>

                <h2 className="headlineMedium pt-5 pb-2 text-uppercase">{t('Where do I find the order id?')}</h2>
                <p>{t('To find your Order ID please, log in to mytheresa.com, go to My Account / My Orders Section, and find the Order ID in Order Details. Alternatively, on th Invoice or somewhere else.')}</p>
                <p>
                    <Trans i18nKey="contactCustomerCareText">
                        If you require further assistance, please <a href="https://www.mytheresa.com/to/contacts/?services=returns&type=contact_page_logintext" target="_blank">contact</a> our Customer Care team.
                    </Trans>
                </p>
            </div>
        </div>
    );
};