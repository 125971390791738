import React, { useEffect } from 'react';
import { useUser, useUserDispatch } from '../../contexts/User';
import { userActionTypes } from '../../contexts/PersistUserReducer';
import SupportContact from '../SupportContact';

export default function ErrorMessage(props) {
    const user = useUser();
    const dispatchUserUpdate = useUserDispatch();

    useEffect(() => {
        dispatchUserUpdate({ type: userActionTypes.clearSession });
    });

    return(
        <div className="Error">
            <SupportContact message="Something went wrong. Kindly contact our Customer Care team to complete your return. We apologize for the inconvenience." channelCode={user.order.channelCode} />
        </div>
    );
};