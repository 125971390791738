import React, { useState } from 'react';
import { useErrorDispatch } from '../contexts/Error';
import { useUser, useUserDispatch } from '../contexts/User';
import { useTranslation, Trans } from 'react-i18next';
import PreviousNextNavigation from './PreviousNextNavigation';
import { checkStock } from '../api/Item';
import ReturnExchangeOrderItemList from './ReturnExchangeOrderItemList';
import MiniCartReturnOrderItem from './MiniCartReturnOrderItem';
import MiniCartExchangeOrderItem from './MiniCartExchangeOrderItem';
import './MiniCart.scss';
import ArrowUpDown from "./ArrowUpDown";
import {useHistory, useParams} from 'react-router-dom';
import FedExAmountAboveMaximumHint from './FedExAmountAboveMaximumHint';

export default function MiniCart(props) {
    const { t } = useTranslation();
    const user = useUser();
    const dispatchErrorUpdate = useErrorDispatch();
    const dispatchUserUpdate = useUserDispatch();
    const history = useHistory();
    const [expanded, setExpanded] = useState(false);
    let { langUrlParam } = useParams();

    const returnOrderItems = user.order.items.filter(item => item.isReturn === true);
    const exchangeOrderItems = user.order.items.filter(item => item.isExchange === true);
    const totalNumberOfItems = returnOrderItems.length + exchangeOrderItems.length;

    const toggleMinicart = () => { setExpanded(!expanded) };

    const getItemsByInvoiceNumber = () => {
        let itemsByInvoiceNumber = {};
        user.order.items.forEach(item => {
                if (typeof itemsByInvoiceNumber[item.invoiceNumber] == 'undefined') {
                    itemsByInvoiceNumber[item.invoiceNumber] = [];
                }
                itemsByInvoiceNumber[item.invoiceNumber].push(item);
            }
        );

        return itemsByInvoiceNumber;
    };

    return (
        <div className={'MiniCart pb-1 pb-lg-0' + (expanded ? ' expanded' : '')}>
            <div className={'loading d-lg-none text-center' + (props.itemAdded ? ' active' : '')}><p className="align-middle">{t('Item added to the list')}</p></div>
            <div className={'content'}>
                <a href="#" className="touchArea" onClick={toggleMinicart}>
                    <h2>{t('Return / Exchange List')}</h2>
                    {returnOrderItems.length === 0 && exchangeOrderItems.length === 0 &&
                    <p className="larger">{t('Your returns and exchanges list is empty, add the items you want to return from the list and click on the "Next" button when you are ready.')}</p>
                    }
                </a>

                {totalNumberOfItems > 0 &&
                <div>
                    <a href="#" className="copySmall touchArea d-block d-lg-none" onClick={toggleMinicart}><p><Trans i18nKey="itemsCurrentlyInList">
                        This list has currently <strong>{{numberOfItems: totalNumberOfItems}} item(s)</strong>, click here to expand the list and see the details.
                    </Trans></p></a>
                    <div className="d-none d-lg-block">
                        {Object.keys(getItemsByInvoiceNumber()).map((invoiceNumber, index) => (
                                <ReturnExchangeOrderItemList
                                    key={index}
                                    heading={(Object.keys(getItemsByInvoiceNumber()).length > 1 ? t('Parcel') : '')}
                                    items={getItemsByInvoiceNumber()[invoiceNumber]}
                                    returnsIntroduction={t('You have chosen to return the below item(s)')}
                                    exchangesIntroduction={t('You have chosen to exchange the below item(s)')}
                                    returnItemType={MiniCartReturnOrderItem}
                                    exchangeItemType={MiniCartExchangeOrderItem}
                                    isMiniCartExpanded={expanded}
                                    setItemAdded={props.setItemAdded}
                                />
                            )
                        )}

                        <PreviousNextNavigation label="Next" showBack={false} action={
                            () => {
                                let redirectToReturnDetails = () => history.push('/' + langUrlParam + '/return-details');
                                if (exchangeOrderItems.length === 0) {
                                    redirectToReturnDetails();
                                    return;
                                }

                                checkStock(exchangeOrderItems.map(item => item.itemNumber), redirectToReturnDetails, dispatchErrorUpdate, dispatchUserUpdate)}
                        }><FedExAmountAboveMaximumHint className="mb-3" /></PreviousNextNavigation>
                    </div>
                </div>
                }
                <div className="d-lg-none"><ArrowUpDown action={toggleMinicart} expanded={expanded} /></div>
            </div>
        </div>
    );
};
