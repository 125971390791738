import {useTracking as useReactTracking} from "react-tracking";
import deepMerge from "../helpers/deepMerge";
import {isMobileOnly, isTablet} from "react-device-detect";
import { useTranslation } from 'react-i18next';

const getDeviceType = () => {
    if (isMobileOnly) {
        return 'mobile';
    } else if (isTablet) {
        return 'tablet';
    }

    return 'desktop';
};

const getViewport = () =>  {
    const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if (viewportWidth < 576) {
        return 'xs';
    } else if (viewportWidth < 768) {
        return 's';
    } else if (viewportWidth < 1024) {
        return 'm';
    } else if (viewportWidth < 1200) {
        return 'l';
    } else {
        return 'xl';
    }
};

export function useTracking() {
    const { i18n } = useTranslation();

    return useReactTracking(
        {}, {
            dispatch: data => {
                if (typeof anData !== 'object') {
                    return;
                }

                let additionalData = {};

                if (typeof data.form == 'object') {
                    additionalData.form = {
                        name: "digital returns"
                    };
                }

                if (data.object === 'page' && data.action === 'view') {
                    additionalData.platform = {
                        environment: process.env.REACT_APP_TRACKING_PLATFORM_ENVIRONMENT ?? "",
                        name: "digital returns"
                    };

                    additionalData.device = {
                        viewport: getViewport(),
                        type: getDeviceType()
                    };

                    additionalData.page = {
                        type: "digitalreturns",
                        sitemap: "digital returns",
                        language: i18n.language.substr(0, 2),
                        department: "none",
                    };

                    additionalData.user = {
                        loggedIn: false,
                        polymere: "unknown",
                        polymereCrit: "unknown",
                    };
                } else {
                    delete data.user;
                    delete data.page;
                }

                window.anData.push(deepMerge(additionalData, data));
            }
        }
    );
}