import React from 'react';
import './SupportContact.scss';
import { Trans } from 'react-i18next';
import SupportEmailContact from './SupportEmailContact';
import SupportPhoneContact from './SupportPhoneContact';

export default function SupportContact({ message, channelCode }) {
    return(
        <div className="SupportContact">
            {message &&
                <div className="row justify-content-center text-center">
                    <div className="col-md-8">
                        <h2 className="mb-5">
                            <Trans>
                                {message}
                            </Trans>
                        </h2>
                    </div>
                </div>
            }
            <div className="row justify-content-center supportInfo">
                <div className="col-md-4 text-center">
                    <SupportEmailContact />
                </div>
                <div className="col-md-4 text-center">
                    <SupportPhoneContact channelCode={channelCode} />
                </div>
            </div>
        </div>
    );
};