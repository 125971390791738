import React from 'react';
import { useTranslation } from 'react-i18next';
import Nav from "react-bootstrap/Nav";
import './GenderMenu.scss';
import MenuLink from "./MenuLink";

export default function GenderMenu(props) {
    const { t } = useTranslation();

    return (
        <Nav className={'GenderMenu' + (props.className ? (' ' + props.className) : '')}>
            <MenuLink label={t('Women')} href="https://www.mytheresa.com/to/women?services=returns&type=women" />
            <MenuLink label={t('Men')} href="https://www.mytheresa.com/to/men?services=returns&type=men" />
            <MenuLink label={t('Kids')} href="https://www.mytheresa.com/to/kids?services=returns&type=kids" />
            <MenuLink label={t('Life')} href="https://www.mytheresa.com/to/life?services=returns&type=life" />
        </Nav>
    )
}