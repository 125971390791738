import React from 'react';
import './GenderMenu.scss';

export default function ChannelSupportPhoneNumber({ channelCode }) {
    const getChannelSupportPhoneNumber = () => {
        switch (channelCode) {
            case '02':
                return '0808 101 3473';
            case '05':
                return '1-888-550-9675';
            case '08':
                return '0120-993-748';
            case '11':
                return '+49 89 127695-400';
            case '12':
                return '800-906-173';
            case '13':
                return '1-800-982-320';
            case '14':
                return '800-101-2544';
            case '18':
                return '003-0813-2723';
            case '19':
                return '+49 89 127695-101';
            case '20':
                return '+49 89 127695-101'
            case '21':
                return '1-888-550-9675';
            default:
                return '+49 89 127695-101';
        }
    }

    return (
        <div className="ChannelSupportPhone">
            <a href={'tel:' + getChannelSupportPhoneNumber()}>{getChannelSupportPhoneNumber()}</a>
        </div>
    )
}