import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ExchangeButton(props) {
    const { t } = useTranslation();

    return(
        <button className="ExchangeButton btn btn-secondary float-md-right mx-auto" type="button" onClick={props.handleButtonClick} disabled={(props.item.lineNumber === props.itemAdded.lineNumber) ? 'disabled' : ''}>
            {(props.itemAdded.type === 'exchange' && props.item.lineNumber === props.itemAdded.lineNumber) ? t('Added') : t('Exchange')}
        </button>
    );
};