import React from 'react';
import './FloatingInput.scss';
import { ErrorMessage, Field, useField } from 'formik';
import { Alert } from "react-bootstrap";

export default function FloatingInput({ fieldType, label, ...props }) {
    const [field, meta] = useField(props);
    const FieldComponent = fieldType ?? Field;

    return(
        <div className="FloatingInput form-label-group text-left">
            <FieldComponent className={'form-control' + (meta.touched && meta.error ? ' error' : '')} placeholder={label} {...field} {...props} />
            <label htmlFor={props.id || props.name}>{label}</label>
            <ErrorMessage {...field}>{msg => <Alert variant="danger">{msg}</Alert>}</ErrorMessage>
        </div>
    );
}