import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderItem from './OrderItem';

export default function NonFunctionalDisplayOrderItem(props) {
    const { t } = useTranslation();

    return (
        <div className="NonFunctionalDisplayOrderItem">
            <OrderItem item={props.item} isNonFunctional={true}/>
        </div>
    );
};