import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import MobileDesktopDropdown from './MobileDesktopDropdown';

export default function SizeSelect({ label, ...props }) {
    const { t } = useTranslation();
    const { field } = useField(props);

    const getSizeValue = function(size) {
        let sizeAdditionPrefix = ' - ';

        switch (size['inventory']) {
            case 1:
                return size['size'] + sizeAdditionPrefix + t('Last piece');
            case 2:
                return size['size'] + sizeAdditionPrefix + t('Low stock');
            default:
        }

        return size['size'];
    }

    const getSizeOptions = function() {
        let sizeOptions = [];
        props.itemsizes.filter(size => size['inventory'] > 0).map(size => sizeOptions.push({key: size['size'], value: getSizeValue(size)}));
        return sizeOptions;
    }

    return (
        <div className="SizeSelect pb-3">
            <MobileDesktopDropdown label={label} defaultOptionLabel="Choose your size*" {...props} {...field} options={getSizeOptions()} />
        </div>
    )
};