import React from 'react';
import Nav from 'react-bootstrap/Nav';
import './MenuIcon.scss';

export default function MenuIcon(props) {
    return (
        <div className="MenuIcon">
            <div className="title d-lg-none d-xl-none">{props.title}</div>
            <Nav.Link className="nav-link p-lg-0 p-xl-0" href={props.href} target="_blank">
                <span className="d-lg-none d-xl-none">{props.label}</span>
                <img src={props.imageSrc} className="d-none d-lg-block" />
            </Nav.Link>
        </div>
    )
}