import React from 'react'
import { useError } from '../contexts/Error';
import { useTranslation, Trans } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import { useTracking } from 'react-tracking';

export default function ApiError() {
    const { t } = useTranslation();
    const error = useError();
    const { trackEvent } = useTracking();

    if (error.errorCode === null) {
        return null;
    }

    const getErrorMessage = () => {
        switch (error.errorCode) {
            case 'https://returns.mytheresa.services/problems/5':
                return <Trans i18nKey={`error::${error.errorCode}`} defaults={t('error::UNKNOWN_ERROR')}><a href="mailto:broker.china@mytheresa.com">broker.china@mytheresa.com</a></Trans>
            case 'https://returns.mytheresa.services/problems/8':
            case 'https://returns.mytheresa.services/problems/420501':
            case 'https://returns.mytheresa.services/problems/420504':
            case 'https://returns.mytheresa.services/problems/420506':
                return <Trans i18nKey={`error::${error.errorCode}`} defaults={t('error::UNKNOWN_ERROR')}><a href="https://www.mytheresa.com/to/contacts/?services=returns" target="_blank" rel="noopener noreferrer">contact</a></Trans>
            default:
                return <Trans i18nKey={`error::${error.errorCode}`} defaults={t('error::UNKNOWN_ERROR')}><a href="https://www.mytheresa.com/to/contacts/?services=returns" target="_blank" rel="noopener noreferrer">contact</a></Trans>
        }
    }

    trackEvent({ object: 'page', action: 'view', page: { errorId: error.errorCode, errorMsg: t([`error::${error.errorCode}`, 'error::UNKNOWN_ERROR']) } });

    return(
        <ErrorMessage className="ApiError" errorCode={error.errorCode}>{getErrorMessage()}</ErrorMessage>
    );
}