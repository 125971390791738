import React from 'react';
import { useUserDispatch } from '../contexts/User';
import { useTracking } from 'react-tracking';
import { isTablet, isMobile } from 'react-device-detect';

export default function AddButton(props) {
    const dispatchUserUpdate = useUserDispatch();
    const { trackEvent } = useTracking();

    const handleButtonClick = () => {
        trackEvent({
            object: 'form',
            action: 'step',
            form: { pathId: '3.0' },
            product: {
                id: props.item.itemNumber,
                name: props.item.itemDescription,
                brandName: props.item.designerName,
                price: props.item.formattedItemPrice
            }
        });

        if (isTablet === true || isMobile === true) {
            props.setItemAdded({
                type: props.type,
                lineNumber: props.item.lineNumber
            })

            setTimeout(() => {
                dispatchUserUpdate({type: props.type.toUpperCase() + '_ORDER_ITEM', lineNumber: props.item.lineNumber});
            }, 100);

            setTimeout(() => {
                props.setItemAdded(false)
            }, 3000);
        } else {
            dispatchUserUpdate({type: props.type.toUpperCase() + '_ORDER_ITEM', lineNumber: props.item.lineNumber});
        }
    }

    return(
        <div className="AddButton">
            {React.cloneElement(props.children, {...props, handleButtonClick: handleButtonClick})}
        </div>
    );
};