import React from 'react';
import { useUserDispatch } from '../contexts/User';
import { useTranslation } from 'react-i18next';
import CloseIcon from "./CloseIcon";

export default function UnExchangeButton(props) {
    const { t } = useTranslation();
    const dispatchUserUpdate = useUserDispatch();

    return(
        <button className="UnExchangeButton removeButton copySmall" type="button" onClick={(event) => dispatchUserUpdate({type: 'UNEXCHANGE_ORDER_ITEM', lineNumber: props.lineNumber})} disabled={props.disabled}>
            <small>{t('Remove')}</small> <CloseIcon />
        </button>
    );
};