import React, { useReducer } from 'react';
import { init, PersistUserReducer } from './PersistUserReducer';
import { initialUserState } from './UserReducer';

const UserContext = React.createContext();
UserContext.displayName = 'User';

const UserDispatchContext = React.createContext();
UserDispatchContext.displayName = 'UserDispatch';

export function useUser() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }

    return context;
}

export function useUserDispatch() {
    const context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error('useUserDispatch must be used within a UserProvider');
    }

    return context;
}

export const UserProvider = ({ children }) => {
    const [user, dispatch] = useReducer(PersistUserReducer, initialUserState, init);

    return (
        <UserContext.Provider value={user}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserContext.Provider>
    );
};