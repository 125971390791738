import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderItem from './OrderItem';
import CommentInput from './CommentInput';
import ReasonSelect from './ReasonSelect';

export default function ReturnDetailsOrderItem(props) {
    const { t } = useTranslation();

    return (
        <div className="ReturnDetailsOrderItem">
            <OrderItem item={props.item}
                reasonSelect={
                    <ReasonSelect name={props.item.lineNumber + '.returnReasonCode'} label={t('Please select a reason for return')} disabled={props.isDisableInputs} />
                }
                commentInput={
                    <CommentInput name={props.item.lineNumber + '.comment'} label={t('Additional comments')} disabled={props.isDisableInputs} />
                }
            />
        </div>
    );
};
