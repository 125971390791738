import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import FloatingInput from './FloatingInput';

export default function CommentInput({ label, ...props }) {
    return(
        <div className="CommentInput mt-4">
            <FloatingInput {...props} fieldType={TextareaAutosize} maxLength="250" label={label} />
        </div>
    );
};