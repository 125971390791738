import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import './RefundViaStoreCreditRadio.scss';
import InfoIconWithTooltip from './InfoIconWithTooltip';

export default function RefundViaStoreCreditRadio(props) {
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(props);

    return(
        <div className="RefundViaStoreCreditRadio py-4 mt-3">
            <h3 className="mt-3 pb-3">{t('Store Credit')}</h3>
            <div className="row pt-3">
                <div className="col-sm-12 col-md-6">
                    {t('Would you like your refund in the form of store credit?')}
                    <InfoIconWithTooltip text="By selecting this option, your refund will be provided as Mytheresa store credit. You will receive an email with a voucher code for your full refund amount. Please note that once the store credit voucher is provided, we will not be able to reverse the refund and provide it in the original payment method." />
                </div>
                <div className="col-sm-12 mx-auto mx-md-0 mt-3 col-md-7 col-lg-5 col-xl-6 mt-3 mt-lg-0">
                    <div className="row">
                        <div className="col-3 col-sm-2">
                            <Form.Check
                                {...field}
                                inline
                                label={t('Yes')}
                                type="radio"
                                value="1"
                                checked={field.value === '1' ? 'checked': ''}
                            />
                        </div>
                        <div className="col-3 col-sm-2">
                            <Form.Check
                                {...field}
                                inline
                                label={t('No')}
                                type="radio"
                                value="0"
                                checked={field.value === '0' ? 'checked': ''}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};