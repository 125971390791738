import React from 'react';
import returnReasonCodes from '../configs/returnReasonCodes';
import { useField } from 'formik';
import MobileDesktopDropdown from './MobileDesktopDropdown';

export default function ReasonSelect({ label, ...props }) {
    const [field, meta, helpers] = useField(props);

    return(
        <div className="ReasonSelect">
            <MobileDesktopDropdown label={label} defaultOptionLabel="Choose an option*" {...props} {...field} options={returnReasonCodes} />
        </div>
    );
};