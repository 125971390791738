import React from 'react'
import { Alert } from 'react-bootstrap';
import ScrollToTop from './ScrollToTop';

export default function ErrorMessage(props) {
    return(
        <div className={'ErrorMessage ' + props.className + ' text-center py-3'}>
            <ScrollToTop />
            {props.children &&
                <Alert variant="danger" data-error-code={props.errorCode}>
                    {props.children}
                </Alert>
            }
        </div>
    )
}