import React from 'react';
import './SupportEmailContact.scss';
import {    useTranslation } from 'react-i18next';
import emailIconImage from '../assets/e-mail.svg';

export default function SupportEmailContact() {
    const { t } = useTranslation();

    return (
        <div className="SupportEmailContact">
            <h3 className="text-uppercase">
                <img className="img-fluid emailIconImage" src={emailIconImage} />{t('Email us')}
            </h3>
            <p><a href="mailto:customercare@mytheresa.com">customercare@mytheresa.com</a></p>
            <p className="copySmall">
                {t('Please send us an email, we will get back to you within 24 hours.')}
            </p>
        </div>
    );
};