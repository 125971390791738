import React from 'react';
import OrderItem from './OrderItem';
import AddButton from './AddButton';
import ReturnButton from './ReturnButton';
import ExchangeButton from './ExchangeButton';
import DisabledExchangeButton from "./DisabledExchangeButton";
import "./SelectOrderItem.scss";
import { useTranslation } from 'react-i18next';

export default function SelectOrderItem(props) {
    const { t } = useTranslation();

    return (
        <div className="SelectOrderItem">
            <OrderItem item={props.item}
                       itemAdded={props.itemAdded}
                       returnButton={
                           props.item.isReturnable === true ?
                               <AddButton type="return" item={props.item} setItemAdded={props.setItemAdded} itemAdded={props.itemAdded}>
                                   <ReturnButton />
                               </AddButton>
                               : undefined
                       }
                       exchangeButton={
                           props.item.isExchangeable === true && ! props.item.isSoldOut ?
                               <AddButton type="exchange" item={props.item} setItemAdded={props.setItemAdded} itemAdded={props.itemAdded}>
                                   <ExchangeButton />
                               </AddButton>
                               : props.item.isExchangeable === true && props.item.isSoldOut ? <DisabledExchangeButton label={t('Sold Out')} /> : undefined
                       }
                       hide={props.item.isReturn || props.item.isExchange}
            />
        </div>
    );
};