import React from 'react';
import './UspBar.scss';
import { useTranslation } from 'react-i18next';

export default function UspBar(props) {
    const { t } = useTranslation();

    return (
        <div className="UspBar">
            <div className="text-center p-2">
                <a className="align-middle contact" href="https://www.mytheresa.com/to/contacts/?services=returns&type=contact_page_tlb" target="_blank">{t('Need help? Contact our customer care team.')}</a>
            </div>
        </div>
    )
}