import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { format as formatDate } from 'date-fns'
import translationEn from './locales/en.json';
import translationDe from './locales/de.json';
import translationEs from './locales/es.json';
import translationFr from './locales/fr.json';
import translationIt from './locales/it.json';
import translationZh from './locales/zh.json';
import translationKo from './locales/ko.json';
import translationAr from './locales/ar.json';

// the translations
const resources = {
    en: {
        translation: translationEn
    },
    de: {
        translation: translationDe
    },
    es: {
        translation: translationEs
    },
    fr: {
        translation: translationFr
    },
    it: {
        translation: translationIt
    },
    zh: {
        translation: translationZh
    },
    ko: {
        translation: translationKo
    },
    ar: {
        translation: translationAr
    }
};

i18n.on('languageChanged', function (lng) {
    const lngIso2 =  lng.substr(0, 2);
    if (window.location.pathname.lastIndexOf('/' + lngIso2, 0) !== 0) {
        window.location.replace('/' + lngIso2 + '/');
    }

    document.documentElement.lang = i18n.language.substr(0,2);
    document.documentElement.dir = i18n.dir();
    document.body.dir = i18n.dir();
})

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: {
            order: ['path', 'querystring', 'navigator'],
            lookupQuerystring: 'lang'
        },
        whitelist: ['en', 'de', 'es', 'fr', 'it', 'zh', 'ko', 'ar'],
        fallbackLng: 'en', // use en if detected lng is not available
        resources,
        nsSeparator: ':::',
        keySeparator: '::',

        interpolation: {
            escapeValue: false, // react already safes from xss
            format: function (value, format, lng) {
                if (value instanceof Date) {
                    return formatDate(value, format);
                }

                return value;
            }
        }
    });

export default i18n;