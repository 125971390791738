import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import { useUser } from '../contexts/User';
import { useTracking } from 'react-tracking';
import {useTranslation} from "react-i18next";

export default function PrivateRoute ({component: Component, ...rest}) {
    const user = useUser();
    const { i18n } = useTranslation();

    const { Track } = useTracking({
        user: { loggedIn: user.isLoggedIn, polymere: user.order.polymere, polymereCrit: user.order.polymereCrit },
        page: { country: user.order.countryCode }
    });

    return (
        <Track>
            <Route
                {...rest} render={(props) => (
                    user.isLoggedIn === true
                        ? <Component {...props} />
                        : <Redirect to={"/" + (props.match.params.langUrlParam ?? i18n.language.substr(0, 2)) + "/"} />
                )}
            />
        </Track>
    )
}