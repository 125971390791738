import React from 'react';
import { useTranslation } from 'react-i18next';
import './OrderItem.scss';
import ProductLink from "./ProductLink";

export default function OrderItem(props) {
    const { t } = useTranslation();

    return (
        <div className={'OrderItem ' + ((props.item.isReturn || props.item.isExchange) ? 'py-4-25' : 'py-4') + (typeof props.itemAdded != 'undefined' && typeof props.itemAdded.lineNumber != 'undefined' && props.item.lineNumber === props.itemAdded.lineNumber ? ' itemAdded': '') + (props.hide ? ' hide' : '')}>
            { props.removeButton && (
                <div className="text-right removeButtonContainer">{props.removeButton}</div>
            ) }
            <div className={'row' + (props.removeButton ? ' pt-2' : '')}>
                <div className={(props.isNonFunctional ? 'col-3' : 'col-4') + (((! props.isMiniCart || props.isMiniCartExpanded) && props.isNonFunctional !== true) ? ' col-sm-3 col-md-2' : '')}>
                    <img className="img-fluid productImage" src={props.item.imageUrl} />
                </div>
                <div className={(props.isNonFunctional ? 'col-9' : 'col-8') + (props.isNonFunctional !== true ? ((props.isMiniCart) ? '' : ' col-md-5' + ((props.item.isReturn || props.item.isExchange) ? ' col-lg-4' : '')) : '')}>
                    <h4 className="text-uppercase"><ProductLink sku={props.item.itemNumber} label={props.item.designerName} /></h4>
                    <p><ProductLink sku={props.item.itemNumber} label={props.item.itemDescription} /></p>
                    <p>{props.item.description}</p>
                    <p>{t('Size')} {props.item.size}</p>
                    <p>{t('Item No.')}: {props.item.itemNumber}</p>
                    {/* //TO DO: uncomment to show price */}
                    {/* <strong>{props.item.formattedItemPrice}</strong> */}
                </div>
                <div className={'col-sm-12 justify-content-end mx-auto mx-md-0 mt-3 ' + (((props.item.isReturn || props.item.isExchange) && !props.itemAdded) ? 'col-md-7 col-lg-5 col-xl-6 mt-3 mt-lg-0' : 'col-md-5 mt-md-0')}>
                    {props.returnButton}
                    {props.exchangeButton}
                    {props.sizeSelect}
                    {props.reasonSelect}
                    {props.commentInput}
                </div>
            </div>
        </div>
    );
};