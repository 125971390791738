import React, { useState } from 'react';
import { useUser } from '../../contexts/User';
import CustomerInfo from '../CustomerInfo';
import OrderInfo from '../OrderInfo';
import MiniCart from '../MiniCart';
import { useTranslation } from 'react-i18next';
import './OrderDetails.scss';
import OrderItemList from '../OrderItemList';
import SelectOrderItem from '../SelectOrderItem';
import NonSelectableOrderItem from '../NonSelectableOrderItem';
import NonReturnableOrderAlert from '../NonReturnableOrderAlert';
import ApiError from '../ApiError';
import { useTracking } from 'react-tracking';

export default function OrderDetails() {
    const { t } = useTranslation();
    const user = useUser();
    const [itemAdded, setItemAdded] = useState(false);

    useTracking({ object: 'form', action: 'step', form: { pathId: '2.0' } }, { dispatchOnMount: true });
    useTracking({ object: 'page', action: 'view', page: { name: 'Order Details' } }, { dispatchOnMount: true });

    return (
        <div className="OrderDetails">
            <ApiError />
            <div className="row justify-content-center text-center">
                <div className="col-xl-10">
                    <CustomerInfo />
                </div>
            </div>
            {user.order.isReturnable === false &&
                <div className="row justify-content-center">
                    <div className="col-xl-10">
                        <NonReturnableOrderAlert />
                    </div>
                </div>
            }
            <div className={"row justify-content-center pt-4" + (user.order.isReturnable === false ? ' inactive' : '')}>
                <div className="col-xl-6">
                    <div className="row">
                        <div className="col">
                            <OrderInfo />
                        </div>
                    </div>
                    <div className="row">
                        {user.order.isReturnable === true
                            ?
                            <div className="col">
                                <div>
                                    <OrderItemList className="pt-3" title={t('Products')} items={user.order.items.filter(item => (item.isReturnable === true || item.isExchangeable === true))} itemType={SelectOrderItem} isShowEmpty={true} setItemAdded={setItemAdded} itemAdded={itemAdded}/>
                                </div>
                                <div>
                                    <OrderItemList className="pt-4" introductionClassName="alert alert-info text-center w-100" introduction={t('Unfortunately, the item(s) in this list can only be returned or exchanged according to the instructions included in your package.')} items={user.order.items.filter(item => item.isTlg === true)} itemType={NonSelectableOrderItem} />
                                </div>
                                <div>
                                    <OrderItemList className="pt-4" introductionClassName="alert alert-danger text-center w-100" introduction={user.order.isReturnable === true ? t('The item(s) in this list cannot be returned.') : ''} items={user.order.items.filter(item => item.isReturnable === false && item.isExchangeable === false && item.isTlg === false)} itemType={NonSelectableOrderItem} />
                                </div>
                                {user.order.items.filter(item => (item.isReturn === true || item.isExchange === true)).length === user.order.items.length &&
                                    <p className="noItemsMessage py-5">{t('No more items to return or exchange.')}</p>
                                }
                            </div>
                            :
                            <div className="col">
                                <OrderItemList className="pt-3" title={t('Products')} items={user.order.items} itemType={NonSelectableOrderItem} />
                            </div>
                        }
                    </div>
                </div>
                <div className="col-xl-1 d-flex justify-content-center">
                    <div className="verticalBorder"></div>
                </div>
                <div className="col-xl-3">
                    <MiniCart itemAdded={itemAdded} setItemAdded={setItemAdded} />
                </div>
            </div>
        </div>
    );
}