import React from 'react';
import './OrderItemList.scss';

export default function OrderItemList(props) {
    const OrderItemType = props.itemType;

    if (props.items.length === 0 && props.isShowEmpty !== true) {
        return null;
    }

    return (
        <div className={'OrderItemList' + (props.className ? ' ' + props.className : '')}>
            {props.title &&
                <h3>{props.title}</h3>
            }

            {props.introduction &&
                <p className={props.introductionClassName}>{props.introduction}</p>
            }

            {props.items.map((item, index) => (
                    <OrderItemType 
                        key={index}
                        item={item}
                        isMiniCartExpanded={props.isMiniCartExpanded}
                        setItemAdded={props.setItemAdded}
                        itemAdded={props.itemAdded}
                        isDisableInputs={props.isDisableInputs}
                    >
                        {props.children}
                    </OrderItemType>
                )
            )}
        </div>
    );
};