import React from 'react';

const loadingDefaultPercentage = 0;
const startLoadingPercentage = 65;
const loadingCompletePercentage = 100;

export const initialUserState = {
    isLoggedIn: false,
    order: {items: []},
    loading: loadingDefaultPercentage,
    sessionExpiresAt: 0
};

export const UserReducer = (state, action) => {
    const startLoading = (state) => {
        return {
            ...state,
            loading: startLoadingPercentage
        };
    }

    const finishLoading = (state) => {
        return {
            ...state,
            loading: loadingCompletePercentage
        };
    }

    const updateOrderItem = (state, lineNumber, type, activate) => {
        if (typeof state.order.items == 'undefined' || ! ['return', 'exchange'].includes(type)) {
            // return unchanged state
            return state;
        }

        // object clone is necessary for reducer to recognize changed values
        let newState = {
            ...state
        };

        newState.order.items.find((item, position) => {
            if (item.lineNumber === action.lineNumber) {
                let statusVariableName = 'is' + type.charAt(0).toUpperCase() + type.slice(1);
                newState.order.items[position][statusVariableName] = activate;
                return;
            }
        });

        // if the selected package amount is lower than the number of selected return and exchange items, reduce the
        // maximum package amount to the sum of selected return and exchange items
        newState.order.packageAmount = Math.min(
            newState.order.packageAmount,
            (newState.order.items.filter(item => (item.isReturn === true || item.isExchange === true))).length
        );

        return newState;
    };

    const updateStock = (state, itemStocks) => {
        // object clone is necessary for reducer to recognize changed values
        let newState = {
            ...state
        };

        newState.order.items.map((item, orderItemIndex) => {
            if (typeof itemStocks[item.itemNumber] != 'undefined') {
                newState.order.items[orderItemIndex].quantityBySize = itemStocks[item.itemNumber].quantityBySize;
            }
        });

        return newState;
    };

    const exchangeOrderItem = (state, lineNumber) => updateOrderItem(state, lineNumber, 'exchange', true);
    const unExchangeOrderItem = (state, lineNumber) => updateOrderItem(state, lineNumber, 'exchange', false);
    const returnOrderItem = (state, lineNumber) => updateOrderItem(state, lineNumber, 'return', true);
    const unReturnOrderItem = (state, lineNumber) => updateOrderItem(state, lineNumber, 'return', false);

    const updateReturnItems = (state, returnItemsData) => {
        let newState = {
            ...state
        };

        if (typeof returnItemsData.packageAmount != 'undefined' && returnItemsData.packageAmount !== '' && Number.isInteger(parseInt(returnItemsData.packageAmount))) {
            newState.order.packageAmount = parseInt(returnItemsData.packageAmount);
        }

        if (typeof returnItemsData.isRefundAsCoupon != 'undefined' && Number.isInteger(parseInt(returnItemsData.isRefundAsCoupon))) {
            newState.order.isRefundAsCoupon = parseInt(returnItemsData.isRefundAsCoupon);
        }

        for (let [lineNumber, returnItemData] of Object.entries(returnItemsData)) {
            lineNumber = parseInt(lineNumber);
            if (newState.order.items.some((item) => item.lineNumber === lineNumber) === false) {
                return newState;
            }

            for (const [fieldName, value] of Object.entries(returnItemData)) {
                newState.order.items.find((item, position) => {
                    if (item.lineNumber === lineNumber) {
                        newState.order.items[position][fieldName] = value;
                    }
                });
            }
        }

        return newState;
    };

    const readSessionExpiresAtFromHeader = () => {
        try {
            return parseInt(action.payload.headers.sessionexpiresat);
        } catch (error) {
            return initialUserState.sessionExpiresAt;
        }
    };

    // the SessionExpiresAt header is contained in multiple api responses, so it will always be set independent from the action
    if (typeof action.payload != 'undefined' && typeof action.payload.headers != 'undefined' && typeof action.payload.headers.sessionexpiresat != 'undefined') {
        state.sessionExpiresAt = readSessionExpiresAtFromHeader();
    }

    switch (action.type) {
        case 'REQUEST_LOGIN':
        case 'REQUEST_SUBMIT_RETURN':
        case 'REQUEST_UPDATE_STOCK':
            return startLoading(state);
        case 'LOGIN_ERROR':
        case 'UPDATE_STOCK_ERROR':
        case 'SUBMIT_RETURN_SUCCESS':
        case 'SUBMIT_RETURN_ERROR':
            return finishLoading(state);
        case 'LOGIN_SUCCESS':
            let order = action.payload.data.order;
            state = finishLoading(state);

            return {
                ...state,
                isLoggedIn: true,
                order: order
            };
        case 'LOGOUT':
            return {
                ...state,
                isLoggedIn: false,
                order: {}
            };
        case 'UPDATE_STOCK_SUCCESS':
            state = finishLoading(state);
            return updateStock(state, action.payload.data.itemStocks);
        case 'RETURN_ORDER_ITEM':
            return returnOrderItem(state, action.lineNumber);
        case 'UNRETURN_ORDER_ITEM':
            return unReturnOrderItem(state, action.lineNumber);
        case 'EXCHANGE_ORDER_ITEM':
            return exchangeOrderItem(state, action.lineNumber);
        case 'UNEXCHANGE_ORDER_ITEM':
            return unExchangeOrderItem(state, action.lineNumber);
        case 'UPDATE_RETURN_ITEMS':
            return updateReturnItems(state, action.returnItemsData);
        case 'REFRESH_SESSION':
            return state;
        default:
            throw new Error('Unhandled action type:' + action.type);
    }
};
