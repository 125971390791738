import React from 'react';
import OrderItem from './OrderItem';
import './NonSelectableOrderItem.scss';

export default function NonSelectableOrderItem(props) {
    return (
        <div className="NonSelectableOrderItem inactive">
            <OrderItem item={props.item} />
        </div>
    );
};