import React from 'react';

const generalErrorCode = 'GENERAL_ERROR';

export const initialErrorState = {
    isError: false,
    errorCode: null
};

export const ErrorReducer = (state, action) => {
    switch (action.type) {
        case 'API_ERROR':
            // general error code for e.g. 412 response code and unreachable API
            let errorCode = generalErrorCode;

            if (typeof action.payload.response != 'undefined'
                && typeof action.payload.response.data != 'undefined'
                &&  typeof action.payload.response.data.type != 'undefined') {
                // the api answers with a response body in case of an error
                errorCode = action.payload.response.data.type;
            }

            return {
                ...state,
                isError: true,
                errorCode: errorCode
            };
            break;
        case 'RESET_API_ERROR':
            return {
                ...state,
                isError: false,
                errorCode: null
            };
    }
};