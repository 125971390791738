import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ReturnButton(props) {
    const { t } = useTranslation();
    return(
        <button className="ReturnButton btn btn-primary mb-2 float-md-right mx-auto" type="button" onClick={props.handleButtonClick} disabled={(props.item.lineNumber === props.itemAdded.lineNumber) ? 'disabled' : ''}>
            {(props.itemAdded.type === 'return' && props.item.lineNumber === props.itemAdded.lineNumber) ? t('Added') : t('Return')}
        </button>
    );
};