import React from 'react';
import './SupportPhoneContact.scss';
import { Trans, useTranslation } from 'react-i18next';
import ChannelSupportPhone from './ChannelSupportPhone';
import phoneIconImage from '../assets/phone.svg';

export default function SupportPhoneContact({ channelCode }) {
    const { t } = useTranslation();

    return (
        <div className="SupportPhoneContact">
            <h3 className="text-uppercase">
                <img className="img-fluid phoneIconImage" src={phoneIconImage} />{t('Call us')}
            </h3>
            <p><strong><ChannelSupportPhone channelCode={channelCode} /></strong></p>
            <p className="copySmall">
                <Trans i18nKey="supportPhoneText">
                    In English: available 24/7

                    In German: Monday to Friday from 8:00 am until 20:00 pm CET and on Saturdays from 9:00 am until 6:00 pm CET*
                    *Except on German public holidays.
                </Trans>
            </p>
        </div>
    );
};