import React, { useEffect, useState } from 'react';
import { useError, useErrorDispatch } from '../../contexts/Error';
import { useUser, useUserDispatch } from '../../contexts/User';
import PreviousNextNavigation from '../PreviousNextNavigation';
import { Trans, useTranslation } from 'react-i18next';
import ReturnExchangeOrderItemList from '../ReturnExchangeOrderItemList';
import ReturnDetailsOrderItem from '../ReturnDetailsOrderItem';
import ExchangeDetailsOrderItem from '../ExchangeDetailsOrderItem';
import { Formik, Form } from 'formik';
import CustomerInfo from '../CustomerInfo';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import ApiError from '../ApiError';
import { useTracking } from 'react-tracking';
import { submitReturns } from '../../api/Order';
import LoadingSpinner from '../LoadingSpinner';
import NumberOfPackagesSelect from '../NumberOfPackagesSelect';
import RefundViaStoreCreditRadio from '../RefundViaStoreCreditRadio';

export default function ReturnDetails() {
    const { t } = useTranslation();
    const user = useUser();
    const dispatchErrorUpdate = useErrorDispatch();
    const dispatchUserUpdate = useUserDispatch();
    const history = useHistory();
    const error = useError();
    const returnedItems = user.order.items.filter(item => item.isReturn === true);
    const exchangedItems = user.order.items.filter(item => item.isExchange === true);
    const returnedAndExchangedItems = user.order.items.filter(item => (item.isReturn === true || item.isExchange === true));
    const [isLoading, setIsLoading] = useState(false);
    const [isReturnItemsReduced, setIsReturnItemsReduced] = useState(returnedAndExchangedItems.length);
    const [isSubmittingInternal, setIsSubmittingInternal] = useState(false);
    let { langUrlParam } = useParams();

    useTracking({ object: 'form', action: 'step', form: { pathId: '4.0' } }, { dispatchOnMount: true });
    useTracking({ object: 'page', action: 'view', page: { name: 'Return Details' } }, { dispatchOnMount: true });


    const isDisplayPackageNumberSelect = () => {
        if (user.order.maxNumberOfPackages <= 1) return false;

        if (returnedAndExchangedItems.length <= 1) return false;

        if (isReturnItemsReduced < returnedAndExchangedItems.length) return false;

        if (isMultipleShipmentsReturnSelected()) return false;

        return true;
    };

    const initInitialValues = () => {
        let initialValues = {};
        returnedAndExchangedItems.map((item) => {
            initialValues[item.lineNumber] = initialValues[item.lineNumber] || {};
            initialValues[item.lineNumber].returnReasonCode = '';

            if (typeof item.returnReasonCode != 'undefined') {
                initialValues[item.lineNumber].returnReasonCode = item.returnReasonCode;
            }

            if (typeof item.comment != 'undefined') {
                initialValues[item.lineNumber].comment = item.comment;
            }

            return;
        });

        exchangedItems.map((item) => {
            initialValues[item.lineNumber] = initialValues[item.lineNumber] || {};
            initialValues[item.lineNumber].exchangeSize = '';

            if (typeof item.exchangeSize != 'undefined') {
                initialValues[item.lineNumber].exchangeSize = item.exchangeSize;
            }
        });

        initialValues.packageAmount = '';

        // if maximum number of packages or the number of selected return and exchange items is 1,
        // then the packageAmount dropdown is not visible for the end user, so the form value is defaulted to 1
        if (parseInt(user.order.maxNumberOfPackages) === 1
            || returnedAndExchangedItems.length === 1
            || isMultipleShipmentsReturnSelected()) {
            initialValues.packageAmount = Number(1).toString();
        } else if (typeof user.order.packageAmount != 'undefined' && Number.isInteger(user.order.packageAmount)) {
            initialValues.packageAmount = user.order.packageAmount.toString();
        }

        initialValues.isRefundAsCoupon = Number(0).toString();

        if (user.order.isRefundAsCoupon != 'undefined' && Number.isInteger(parseInt(user.order.isRefundAsCoupon))) {
            initialValues.isRefundAsCoupon = user.order.isRefundAsCoupon.toString();
        }

        return initialValues;
    };

    const splitSubmitReturnOrder = () => {
        const itemsByInvoiceNumber = new Map()

        for (const item of returnedAndExchangedItems) {
            const { invoiceNumber } = item

            if (!invoiceNumber) continue;
            if (!itemsByInvoiceNumber.has(invoiceNumber))
                itemsByInvoiceNumber.set(invoiceNumber, [])

            itemsByInvoiceNumber.get(invoiceNumber).push(item)
        }

        const returnOrders = []

        for (const [invoiceNumber, items] of itemsByInvoiceNumber.entries()) {
            const cloneOrder = JSON.parse(JSON.stringify(user.order))

            cloneOrder.invoiceNumber = invoiceNumber
            cloneOrder.items = items

            returnOrders.push(cloneOrder)
        }

        return returnOrders
    };

    const isMultipleShipmentsReturnSelected = () => {
        return splitSubmitReturnOrder().length > 1;
    }

    const disablePageErrorCodes = [
        'https://returns.mytheresa.services/problems/5',
        'https://returns.mytheresa.services/problems/996',
        'https://returns.mytheresa.services/problems/1001',
        'https://returns.mytheresa.services/problems/420501',
        'https://returns.mytheresa.services/problems/420504',
        'https://returns.mytheresa.services/problems/420506'
    ];

    // use indexOf() as includes() is not supported by IE (and polyfills are currently not integrated)
    const isDisablePage = (disablePageErrorCodes.indexOf(error.errorCode) >= 0);

    useEffect(() => {
        if (isSubmittingInternal === true) {
            submitReturnWithLoadingPage()
            setIsSubmittingInternal(false);
        }
    });

    if (returnedAndExchangedItems.length === 0) {
        return <Redirect to={"/" + langUrlParam + "/order-details"}/>
    }

    const submitReturnWithLoadingPage = () => {
        const submitOrders = splitSubmitReturnOrder();
        submitReturns(submitOrders, dispatchErrorUpdate, dispatchUserUpdate)
            .then(submitReturnResponse => {
                setIsLoading(false);

                if (submitReturnResponse.filter(item => item.status === 'fulfilled').length === 0) {
                    history.push({
                        pathname: '/' + langUrlParam + '/error'
                    });
                    return;
                }

                history.push({
                    pathname: '/' + langUrlParam + '/success',
                    submitReturnOrders: submitOrders,
                    submitReturnResponse: submitReturnResponse
                });
                history.block();
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    return (
        <div className="ReturnDetails">
            {isLoading === true ?
                <div className="row justify-content-center text-center">
                    <div className="col-md-6 col-xl-4">
                        <h2 className="pb-4">{t('Preparing your label...')}</h2>
                        <LoadingSpinner/>
                    </div>
                </div>
                :
                <div>
                    <ApiError />
                    <div className={'row justify-content-center text-center'}>
                        <div className="col-xl-8">
                            <CustomerInfo />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <Formik
                                enableReinitialize={true}
                                validateOnMount={true}
                                initialValues={initInitialValues()}
                                validate={values => {
                                    let errors = {};
                                    let mandatoryOrderItemFields = ['returnReasonCode', 'exchangeSize'];

                                    for (const [lineNumber, returnItemData] of Object.entries(values)) {
                                        for (const [fieldName, value] of Object.entries(returnItemData)) {
                                            if (mandatoryOrderItemFields.includes(fieldName) === true && value === '') {
                                                errors[lineNumber] = errors[lineNumber] || {};
                                                errors[lineNumber][fieldName] = t('Required');
                                            }
                                        }
                                    }

                                    if (typeof values['packageAmount'] == 'undefined' || values['packageAmount'] == '') {
                                        errors['packageAmount'] = t('Required');
                                    }

                                    if (typeof values['isRefundAsCoupon'] == 'undefined' || values['isRefundAsCoupon'] == '') {
                                        errors['isRefundAsCoupon'] = t('Required');
                                    }

                                    return errors;
                                }}
                                onSubmit={(values, { setSubmitting, setErrors }) => {
                                    setSubmitting(true);
                                    setIsSubmittingInternal(true);
                                    setIsLoading(true);
                                    dispatchUserUpdate({ type: 'UPDATE_RETURN_ITEMS', returnItemsData: values});
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    dirty,
                                    isValid
                                  }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <div className={isDisablePage ? 'inactive' : ''}>
                                            <ReturnExchangeOrderItemList
                                                items={user.order.items}
                                                returnsIntroduction={t('You have chosen to return the below item(s)')}
                                                exchangesIntroduction={t('You have chosen to exchange the below item(s)')}
                                                returnItemType={ReturnDetailsOrderItem}
                                                exchangeItemType={ExchangeDetailsOrderItem}
                                                isDisableInputs={isDisablePage}
                                            />

                                            {exchangedItems.length > 0 &&
                                                <p>
                                                    <Trans i18nKey="exchangeInformationText">
                                                        Your exchange request will be proceeded once you click the "Confirm" button below.<br /><br />'Please return the original items within 14 days or your credit card will be charged for a full price for the exchanges items that will be sent to you.
                                                    </Trans>
                                                </p>
                                            }

                                            {user.order.isRefundAsCouponAvailable && returnedItems.length > 0 &&
                                                <div className="row">
                                                    <div className="col">
                                                        <RefundViaStoreCreditRadio name="isRefundAsCoupon" />
                                                    </div>
                                                </div>
                                            }

                                            {isDisplayPackageNumberSelect() &&
                                                <div className="row">
                                                    <div className="col">
                                                        <NumberOfPackagesSelect name="packageAmount" />
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <PreviousNextNavigation
                                            type="submit"
                                            label="Confirm"
                                            disabled={!isValid || isDisablePage}
                                            disableBack={isDisablePage}
                                            backAction={() => dispatchUserUpdate({ type: 'UPDATE_RETURN_ITEMS', returnItemsData: values})}
                                        />
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
