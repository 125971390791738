import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTracking } from 'react-tracking';
import { isIOS, isChrome } from 'react-device-detect';

export default function PdfBase64DownloadButton({title, fileName, pdfBase64, trackingData}) {
    const { t } = useTranslation();
    const { trackEvent } = useTracking();

    const downloadPdfBlobWithFilename = function(base64Data, fileName) {
        var byteCharacters = atob(base64Data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: 'application/pdf;base64' });

        let a = document.createElement('a');
        a.style = 'display: none';
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };


    return(
        <div className="PdfBase64DownloadButton">
            {isIOS === true && isChrome === false ?
                 <button type="button" className="btn btn-primary" onClick={() => {
                    downloadPdfBlobWithFilename(pdfBase64, fileName);
                    (typeof trackingData === 'object') && trackEvent(trackingData);
                }}>{t(title)}</button>
                :
                <a className="btn btn-primary"
                   href={'data:application/pdf;base64,' + pdfBase64}
                   download={fileName}
                   onClick={() => (typeof trackingData === 'object') && trackEvent(trackingData)}
                   target="_blank">{t(title)}</a>
            }
        </div>
    );
};