import React from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from './BackButton';
import "./PreviousNextNavigation.scss";

export default function PreviousNextNavigation(props) {
    const { t } = useTranslation();

    return(
        <div className="PreviousNextNavigation text-center p-3 pb-4 pb-md-3">
            {props.children}
            <div className="btn-group d-inline-flex flex-column flex-lg-row-reverse" role="group" aria-label="Navigation">
                {props.type === 'submit' ?
                    <button className="btn btn-primary" type={props.type} onClick={props.action} disabled={props.disabled}>{t(props.label)}</button> :
                    <a onClick={props.action} href={props.link} className={"btn btn-primary" + (props.disabled ? ' disabled' : '')}>{t(props.label)}</a>
                }

                {props.showBack !== false &&
                    <BackButton disabled={props.disableBack} backAction={props.backAction} />
                }
            </div>
        </div>
    );
};