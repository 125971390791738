import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageMenu.scss';
import Nav from 'react-bootstrap/Nav';
import MenuLink from './MenuLink';
import LanguageModal from './LanguageModal';

const languages = [
    { code: 'en', label: 'English' },
    { code: 'de', label: 'German' },
    { code: 'es', label: 'Spanish' },
    { code: 'fr', label: 'French' },
    { code: 'it', label: 'Italian' },
    { code: 'ko', label: 'Korean' },
    { code: 'zh', label: 'Chinese' },
    { code: 'ar', label: 'Arabic' }
];

const getCurrentLanguage = function(languageCode)
{
    const currentLanguage = languages.filter(function(language) {
        return language.code === languageCode;
    });

    if (currentLanguage.length === 0) {
        return { code: '', label: '' };
    }

    return currentLanguage[0];
};

export default function LanguageMenu(props) {
    const { i18n, t } = useTranslation();
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <Nav className={'LanguageMenu' + (props.className ? (' ' + props.className) : '')}>
            <MenuLink label={t(getCurrentLanguage(i18n.language).label)}  onClick={() => setModalShow(true)} />
            <LanguageModal show={modalShow} onHide={() => setModalShow(false)} languages={languages} />
        </Nav>
    )
}