import React, { useState } from 'react';
import './DisabledExchangeButton.scss';
import { useTranslation } from 'react-i18next';
import { isTablet, isMobile } from 'react-device-detect';

export default function DisabledExchangeButton(props) {
    const { t } = useTranslation();
    const [isDisplayDisclaimer, setIsDisplayDisclaimer] = useState(false)

    return(
        <div className="DisabledExchangeButton">
            <div className="buttonContainer float-md-right mx-auto">
                <button className="btn btn-secondary" type="button" onClick={() => setIsDisplayDisclaimer(isDisplayDisclaimer => (isMobile === true || isTablet === true) ? !isDisplayDisclaimer: isDisplayDisclaimer)}>{props.label}</button>
                <span className={'d-none copySmall' + (isDisplayDisclaimer ? ' d-block' : '')}>{t('This product is sold out and cannot be exchanged.')}</span>
            </div>
        </div>
    );
};