import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderItem from './OrderItem';
import SizeSelect from './SizeSelect';
import ReasonSelect from './ReasonSelect';

export default function ExchangeDetailsOrderItem(props) {
    const { t } = useTranslation();

    return (
        <div className="ExchangeDetailsOrderItem">
            <OrderItem item={props.item}
                sizeSelect={
                    typeof props.item.quantityBySize != 'undefined' ?
                    <SizeSelect name={props.item.lineNumber + '.exchangeSize'} label={t('Please choose another size')} itemsizes={props.item.quantityBySize} disabled={props.isDisableInputs} />
                    : null
                }
                reasonSelect={
                    <ReasonSelect name={props.item.lineNumber + '.returnReasonCode'} label={t('Please select a reason for exchange')} disabled={props.isDisableInputs} />
                }
            />
        </div>
    );
};
