import React from 'react';
import './MobileDesktopDropdown.scss';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, useField } from 'formik';
import { isTablet, isMobile } from 'react-device-detect';
import { Alert } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';

export default function MobileDesktopDropdown({ label, defaultOptionLabel, ...props }) {
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(props);
    const { setValue } = helpers;

    const desktopDropdownProperties = {
        label: field.value ?
            ((props.options.filter(option => String(option.key) === field.value)).length > 0 ? (props.options.filter(option => String(option.key) === field.value))[0]['value'] : defaultOptionLabel ? defaultOptionLabel : 'Choose an option*')
            : defaultOptionLabel ? defaultOptionLabel : 'Choose an option*',
        value: field.value,
        toggleClassName: field.value ? '' : 'isDefaultValue'
    };
    const handleDropDownSelect = (label, value) => {
        setValue(value);
    };

    // The react-device-detect library has a bug for specific tablet devices
    // (https://github.com/duskload/react-device-detect/issues/112).
    // E.g. iPad Air and iPad 5th generation are also recognized as desktop
    // (isBrowser = true). Therefore we can't use the components <BrowserView>
    // and <MobileView> here and display the desktop view only in case that the
    // tablet _and_ mobile detection (contains tablet and mobile) return false.
    return(
        <div className="MobileDesktopDropdown">
            {isTablet === false && isMobile === false
                ?
                <div>
                    <label htmlFor={props.id || props.name}>{label}</label>
                    <Field type="hidden" className="form-control" {...field} />

                    <Dropdown>
                        <Dropdown.Toggle className={desktopDropdownProperties.toggleClassName + (meta.touched && meta.error ? ' error' : '')} variant="success" data-flip="false" disabled={props.disabled}>
                            {t(desktopDropdownProperties.label)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu popperConfig={{placements: ['bottom']}}>
                            {props.options.map((option, index) => (
                                <Dropdown.Item key={index} active={option['key'] === desktopDropdownProperties.value} onClick={() => handleDropDownSelect(option['value'], option['key'])}>{t(option['value'])}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>

                    <ErrorMessage {...field}>{msg => <Alert variant="danger">{msg}</Alert>}</ErrorMessage>
                </div>
                :
                <div>
                    <label htmlFor={props.id || props.name}>{label}</label>
                    <Field as="select" className={'form-control' + (meta.touched && meta.error ? ' error' : '')} {...field} {...props}>
                        <option value="">{t('Choose an option*')}</option>
                        {props.options.map((option, index) => (
                            <option key={index} value={option['key']}>{t(option['value'])}</option>
                        ))}
                    </Field>
                    <ErrorMessage {...field}>{msg => <Alert variant="danger">{msg}</Alert>}</ErrorMessage>
                </div>
            }
        </div>
    );
}