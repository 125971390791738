import Api from './Api';
import qs from "qs";

const api = Api('session');

export async function refreshSession(order, dispatchErrorUpdate, dispatchUserUpdate) {
    try {
        const successResponse = await api.post('refresh', qs.stringify(order));
        dispatchUserUpdate({ type: 'REFRESH_SESSION', payload: successResponse });

        return successResponse;
    } catch (error) {
        dispatchErrorUpdate({type: 'API_ERROR', payload: error});
        dispatchUserUpdate({type: 'LOGOUT'});
    }
}