import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function BackButton(props) {
    const { t } = useTranslation();
    const history = useHistory();

    return(
        <button type="button" className="BackButton btn btn-secondary" onClick={() => {if (typeof props.backAction === 'function') props.backAction(); history.goBack();}} disabled={props.disabled}>{t('Back')}</button>
    );
};