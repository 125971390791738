import React from 'react';
import { UserReducer } from './UserReducer';

const sessionStorageKeys = {
    order: 'drp.userOrder',
    sessionExpiresAt: 'drp.sessionExpiresAt'
};

const isSameOrderState = (fromSessionOrder, initialStateOrder) => {
    return JSON.stringify(fromSessionOrder) === JSON.stringify(initialStateOrder);
};

export const userActionTypes = {
    clearSession: 'CLEAR_SESSION'
};

export const init = (initialState) => {
    const readOrderFromSession = () => {
        try {
            let order = JSON.parse(sessionStorage.getItem(sessionStorageKeys.order));
            if (order === null || order.items == 'undefined') {
                throw 'invalid order object';
            }

            return order;
        } catch (error) {
            clearPersistState();
            return initialState.order;
        }
    };

    const readSessionExpiresAtFromSession = () => {
        try {
            let sessionExpiresAt = sessionStorage.getItem(sessionStorageKeys.sessionExpiresAt);
            if (sessionExpiresAt === null) {
                throw 'missing expiry time in session storage';
            }

            return parseInt(sessionExpiresAt);
        } catch (error) {
            clearPersistState();
            return initialState.sessionExpiresAt;
        }
    };

    const isUserLoggedIn = !isSameOrderState(readOrderFromSession(), initialState.order);

    return {
        ...initialState,
        isLoggedIn: isUserLoggedIn || initialState.isLoggedIn,
        order: isUserLoggedIn ? readOrderFromSession() : initialState.order,
        sessionExpiresAt: readSessionExpiresAtFromSession(),
    }
};

const persistState = (state) => {
    sessionStorage.setItem(sessionStorageKeys.order, JSON.stringify(state.order));
    sessionStorage.setItem(sessionStorageKeys.sessionExpiresAt, state.sessionExpiresAt);
};

const clearPersistState = () => {
    Object.keys(sessionStorageKeys).map(storageKey => {
        sessionStorage.removeItem(sessionStorageKeys[storageKey]);
    });
};

export const PersistUserReducer = (state, action) => {
    if (action.type === userActionTypes.clearSession) {
        clearPersistState();
        return state;
    }

    let newState = UserReducer(state, action);
    if (action.type === 'LOGOUT') {
        clearPersistState();
    } else {
        persistState(newState);
    }

    return newState;
};
