import React from 'react';
import { createRoot } from 'react-dom/client';
import i18n from './i18n';
import './styles/_bootstrapCustom.scss';
import DigitalReturnsApp from './DigitalReturnsApp';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserProvider } from './contexts/User';
import { ErrorProvider } from './contexts/Error';

const container = document.getElementById('root');
const root = createRoot(container);

/* Note: we do not use router basename functionality because the language switcher cannot change urls without reload then */
root.render(
    <React.StrictMode>
      <Router>
          <ErrorProvider>
              <UserProvider>
                <DigitalReturnsApp />
              </UserProvider>
          </ErrorProvider>
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
