import React from 'react';
import { Trans } from 'react-i18next';
import { useUser } from '../contexts/User';

export default function NonReturnableOrderAlert() {
    const user = useUser();

    return (
        <div className="NonReturnableOrderAlert alert alert-info text-center w-100">
            <Trans i18nKey="orderAlreadyReturnedText">
                Your order #{{orderId: user.order.id}} already has a label. Check your email. If you have any problems or want to change it, please <a href="https://www.mytheresa.com/to/contacts/" target="_blank">contact</a> our Customer Care team.
            </Trans>
        </div>
    );
}