import React from 'react';
import { useTranslation } from 'react-i18next';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './InfoIconWithTooltip.scss';
import infoIconImage from '../assets/info-icon.svg';

export default function InfoIconWithTooltip(props) {
    const { t } = useTranslation();

    return(
        <span className="InfoIconWithTooltip">
            <OverlayTrigger placement="right" overlay={
                <Tooltip>
                    {t(props.text)}
                </Tooltip>
            }>
                <img className="img-fluid infoIconImage" src={infoIconImage} />
            </OverlayTrigger>
        </span>
    );
};