import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';

export default function Footer(props) {
    const { t } = useTranslation();

    return (
        <footer className="container-fluid">
            <div className="row pb-3">
                <div className="col-sm-12 d-flex justify-content-center">
                    <a href="https://www.mytheresa.com/to/contacts/?services=returns&type=contact_page_footer" target="_blank">{t('Contact us')}</a> <a href="https://www.mytheresa.com/to/customer-care/welcome?services=returns&type=customer_care" target="_blank">{t('Customer Care')}</a>
                </div>
            </div>
            <div className="row pt-2 pb-3">
                <div className="col-sm-12 d-flex justify-content-center">
                    <a href="https://www.mytheresa.com/to/service/terms-conditions/?services=returns&type=t&c" target="_blank">{t('Terms & Conditions')}</a> <a href="https://www.mytheresa.com/to/service/privacy-policy?services=returns&type=privacy_policy" target="_blank">{t('Privacy Policy')}</a> <a href="https://www.mytheresa.com/to/service/imprint?services=returns&type=imprint" target="_blank">{t('Imprint')}</a>
                </div>
            </div>
            <div className="row pt-2">
                <div className="copyright col-sm-12 d-flex justify-content-center" dir="ltr">
                    &copy; {t('2006-' + (new Date()).getFullYear() + ' mytheresa.com')}
                </div>
            </div>
        </footer>
    )
}