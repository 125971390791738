import React from 'react';
import { useUserDispatch } from '../contexts/User';
import { useTranslation } from 'react-i18next';
import CloseIcon from "./CloseIcon";

export default function UnReturnButton(props) {
    const { t } = useTranslation();
    const dispatchUserUpdate = useUserDispatch();

    return(
        <button className="UnReturnButton removeButton copySmall" type="button" onClick={(event) => {
            if (typeof props.setItemAdded != 'undefined') {
                props.setItemAdded(false);
            }

            dispatchUserUpdate({type: 'UNRETURN_ORDER_ITEM', lineNumber: props.lineNumber})}
        } disabled={props.disabled}>
            {t('Remove')} <CloseIcon />
        </button>
    );
};