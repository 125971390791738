import React from 'react';
import OrderItem from './OrderItem';
import UnExchangeButton from './UnExchangeButton';

export default function MiniCartExchangeOrderItem(props) {
    return (
        <div className="MiniCartExchangeOrderItem">
            <OrderItem item={props.item}
                removeButton={
                    <UnExchangeButton lineNumber={props.item.lineNumber} />
                }
                isMiniCart="true" isMiniCartExpanded={props.isMiniCartExpanded}
            />
        </div>
    );
};