import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuLink from "./MenuLink";
import MenuIcon from "./MenuIcon";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './Menu.scss';
import MyAccountLogo from '../assets/my-account.svg';
import GenderMenu from "./GenderMenu";
import LanguageMenu from "./LanguageMenu";

const mainItems = [
    { label: 'New Arrivals', link: 'https://www.mytheresa.com/to/women/new-arrivals/current-week?services=returns&type=new_arrivals' },
    { label: 'Designers', link: 'https://www.mytheresa.com/to/women/designers?services=returns&type=designers' },
    { label: 'Clothing', link: 'https://www.mytheresa.com/to/women/clothing?services=returns&type=clothing' },
    { label: 'Shoes', link: 'https://www.mytheresa.com/to/women/shoes?services=returns&type=shoes' },
    { label: 'Bags', link: 'https://www.mytheresa.com/to/women/bags?services=returns&type=bags' },
    { label: 'Accessories', link: 'https://www.mytheresa.com/to/women/accessories?services=returns&type=accessories' },
    { label: 'Sale', link: 'https://www.mytheresa.com/to/women/sale?services=returns&type=sale' },
];

export default function Menu(props) {
    const { t } = useTranslation();

    return (
        <Navbar.Collapse id={props.id}>
            <div className="navbarCollapseContainer">
                <GenderMenu className="d-xl-none d-lg-none" />
                <Nav className="subNavigation mx-auto">
                    {mainItems.map(({ label, link, ...rest }, index) => (
                        <MenuLink key={index} label={t(label)} href={t(link)} />
                    ))}
                </Nav>
                <div className="otherMenu">
                    <MenuIcon title={t('My Account')} label={t('Login')} href={t('https://www.mytheresa.com/to/account/orders?services=returns&type=my_account')} imageSrc={MyAccountLogo} />
                </div>

                <LanguageMenu className="d-xl-none d-lg-none" />
            </div>
            <div className="darkBackgroundOverlay"></div>
        </Navbar.Collapse>
    )
}