import React from 'react';
import { useTranslation } from 'react-i18next';
import {Button, ListGroup, Modal} from "react-bootstrap";
import "./LanguageModal.scss"
import CloseIcon from "./CloseIcon";
import {useHistory, useLocation} from 'react-router-dom';

export default function LanguageModal(props) {
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language.substr(0,2));
    const history = useHistory();
    const location = useLocation();

    const switchToChosenLanguage = () => {
        let paths = location.pathname.split('/');
        paths.splice(0,2);
        paths.unshift(selectedLanguage);

        history.push('/' + paths.join('/'));
        i18n.changeLanguage(selectedLanguage);
        hideModal();
    };

    const hideModal = () => {
        setSelectedLanguage(i18n.language.substr(0,2));
        props.onHide();
    };

    return (
        <Modal {...props} className="LanguageModal" onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" animation={false} centered>
            <Modal.Header className="py-0 pt-2 px-4">
                <Modal.Title id="contained-modal-title-vcenter" className="text-uppercase titleSmall pt-4 pb-3 px-0"/>
                <a onClick={hideModal}><CloseIcon /></a>
            </Modal.Header>
            <Modal.Body className="py-1 px-0">
                <span className="px-4">{t('Please select your language')}</span>

                <ListGroup variant="flush" className="py-4">
                {props.languages.map((language, index) => (
                    <ListGroup.Item key={index} action className="px-4" active={selectedLanguage === language["code"]} onClick={() => setSelectedLanguage(language["code"])}>{t(language["label"])}</ListGroup.Item>
                ))}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer className="py-4">
                <Button onClick={() => switchToChosenLanguage()} disabled={selectedLanguage === false}>{t('Save changes')}</Button>
            </Modal.Footer>
        </Modal>
    )
}