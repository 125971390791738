import React, { useReducer } from 'react';
import { initialErrorState, ErrorReducer } from './ErrorReducer';

const ErrorContext = React.createContext();
ErrorContext.displayName = 'Error';

const ErrorDispatchContext = React.createContext();
ErrorDispatchContext.displayName = 'ErrorDispatch';

export function useError() {
    const context = React.useContext(ErrorContext);
    if (context === undefined) {
        throw new Error('useError must be used within a ErrorProvider');
    }

    return context;
}

export function useErrorDispatch() {
    const context = React.useContext(ErrorDispatchContext);
    if (context === undefined) {
        throw new Error('useErrorDispatch must be used within a ErrorProvider');
    }

    return context;
}

export const ErrorProvider = ({ children }) => {
    const [error, dispatch] = useReducer(ErrorReducer, initialErrorState);

    return (
        <ErrorContext.Provider value={error}>
            <ErrorDispatchContext.Provider value={dispatch}>
                {children}
            </ErrorDispatchContext.Provider>
        </ErrorContext.Provider>
    );
};