import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../contexts/User';
import { Alert } from 'react-bootstrap';

export default function FedExAmountAboveMaximumHint(props) {
    const { t } = useTranslation();
    const user = useUser();

    const isAmountAboveMaximum = () => {
        let priceAggregated = user.order.items
            .filter(item => (item.isReturn || item.isExchange))
            .reduce((totalSum, item) => totalSum + item.itemPrice, 0);

        return priceAggregated > user.order.fedExMaximumReturnAmount
    };

    // in case of e.g. Taiwan orders the FedEx maximum return amount can be empty and then has to be deactivated
    if (user.order.shippingAgentCode !== 'FEDEX'
        || (user.order.shippingAgentCode === 'FEDEX' && user.order.fedExMaximumReturnAmount === 0)) {
        return null;
    }

    const getThresholdText = () => {
        if (user.order.countryCode === 'CA') return t('orderDetails.CAFEDEXCourier.threshold')

        return t('fedExAmountAboveMaximumHint', {fedExMaximumReturnAmountWithCurrency: user.order.fedExMaximumReturnAmountWithCurrency})
    }


    if (isAmountAboveMaximum()) {
        return(
            <div className="FedExAmountAboveMaximumHint">
                <Alert {...props} variant="danger">
                    { getThresholdText() }
                </Alert>
            </div>
        );
    }

    return null;
}