import React from 'react';
import { useUser, useUserDispatch } from '../contexts/User';
import OrderItem from './OrderItem';
import UnReturnButton from './UnReturnButton';

export default function MiniCartReturnOrderItem(props) {
    return (
        <div className="MiniCartReturnOrderItem">
            <OrderItem item={props.item}
                removeButton={
                    <UnReturnButton lineNumber={props.item.lineNumber} setItemAdded={props.setItemAdded}/>
                }
                isMiniCart="true" isMiniCartExpanded={props.isMiniCartExpanded}
            />
        </div>
    );
};