import Api from './Api';
import qs from 'qs';

const api = Api('item');

export function checkStock(itemIds, successAction, dispatchErrorUpdate, dispatchUserUpdate) {
    dispatchErrorUpdate({ type: 'RESET_API_ERROR' });
    dispatchUserUpdate({ type: 'REQUEST_UPDATE_STOCK' });

    api.post('stock', qs.stringify({items: itemIds}))
        .then(successResponse => {
            dispatchUserUpdate({ type: 'UPDATE_STOCK_SUCCESS', payload: successResponse });
            successAction(successResponse);

            return true;
        })
        .catch(error => {
            dispatchUserUpdate({ type: 'UPDATE_STOCK_ERROR', payload: error });
            dispatchErrorUpdate({ type: 'API_ERROR', payload: error });
            return false;
        });
}