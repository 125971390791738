import axios from 'axios';

export default function Api(endpointUrl) {
    axios.defaults.params = {};
    axios.defaults.params['v'] = process.env.REACT_APP_VERSION;

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL + '/' + endpointUrl,
        timeout: process.env.REACT_APP_API_TIMEOUT_IN_MS,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        withCredentials: true
    });

    return(api);
};