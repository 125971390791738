const returnReasonCodes = [
    {key: '1', value: 'Too small'},
    {key: '2', value: 'Too big'},
    {key: '3', value: 'Too narrow/tight'},
    {key: '4', value: 'Too wide'},
    {key: '5', value: 'Not as depicted'},
    {key: '6', value: 'Not as described'},
    {key: '7', value: 'Do not like material'},
    {key: '8', value: 'Poor quality'},
    {key: '9', value: 'Do not like colour'},
    {key: '11', value: 'Others'}
];

export default returnReasonCodes;