import Api from './Api';
import qs from 'qs';
import 'allsettled-polyfill';

const api = Api('order');

export function loginUser(orderId, email, recaptchaToken, dispatchErrorUpdate, dispatchUserUpdate) {
    dispatchErrorUpdate({ type: 'RESET_API_ERROR' });
    const requestOptions = {
        email: email,
        recaptchaToken: recaptchaToken
    };

    dispatchUserUpdate({ type: 'REQUEST_LOGIN' });

    api.post(orderId, qs.stringify(requestOptions))
        .then(successResponse => {
            dispatchUserUpdate({ type: 'LOGIN_SUCCESS', payload: successResponse });
            return true;
        })
        .catch(error => {
            dispatchUserUpdate({ type: 'LOGIN_ERROR', payload: error });
            dispatchErrorUpdate({ type: 'API_ERROR', payload: error});
            return false;
        });

    return false;
}

export async function logout(dispatchErrorUpdate, dispatchUserUpdate) {
    dispatchErrorUpdate({ type: 'RESET_API_ERROR' });
    dispatchUserUpdate({ type: 'LOGOUT' });
}

export async function submitReturns(orders, dispatchErrorUpdate, dispatchUserUpdate) {
    dispatchErrorUpdate({type: 'RESET_API_ERROR'});
    dispatchUserUpdate({type: 'REQUEST_SUBMIT_RETURN'});

    try {
        const successResponse = await Promise.allSettled(Object.keys(orders).map(orderNumber => api.post('return/' + orders[orderNumber].id, qs.stringify(orders[orderNumber]))));

        if (orders.length === 1 && successResponse.filter(item => item.status === 'fulfilled').length === 0) {
            throw successResponse[0].reason;
        }

        dispatchUserUpdate({type: 'SUBMIT_RETURN_SUCCESS', payload: successResponse});
        return successResponse;
    } catch (error) {
        dispatchUserUpdate({type: 'SUBMIT_RETURN_ERROR', payload: error});
        dispatchErrorUpdate({type: 'API_ERROR', payload: error});

        throw error;
    }
}