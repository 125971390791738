const emailRegEx = new RegExp('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}$', 'i');
const orderNumberRegEx = new RegExp('^[A-Z]+\\d+(?:$|[HL])$', 'i');

// This checks if the Order Number belongs to a split shipment.
// Split shipments have the parent Order Number with the suffix of "H" or "L".
const splitShipmentOrderNumberRegEx = new RegExp('^[A-Z]+\\d+(:$|[HL])$', 'i');

const trimOrderNumber = (splitShipmentOrder) => {
  return splitShipmentOrder.substring(0, splitShipmentOrder.length - 1);
}

export {
  emailRegEx,
  orderNumberRegEx,
  splitShipmentOrderNumberRegEx,
  trimOrderNumber
};