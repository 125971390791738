import React from 'react';
import './MenuBurger.scss';
import Navbar from 'react-bootstrap/Navbar';
import CloseIcon from "./CloseIcon";

export default function MenuBurger(props) {
    function getNavbarToggleIcon() {
        return props.isNavbarCollapsed ? (
            <span className="navbar-toggler-icon"></span>
        ) : (
            <CloseIcon />
        );
    }

    return (
        <Navbar.Toggle aria-controls="navbarMainMenu" children={getNavbarToggleIcon()} className="MenuBurger" />
    )
}
